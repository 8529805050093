import React, {FunctionComponent, useCallback, useMemo} from "react";
import TabPanel, {TabInfo} from "../../../../components/layouts/TabPanel";
import CreditCardPaymentForm from "./CreditCardPaymentForm";
import AchPaymentForm from "./AchPaymentForm";
import {PaymentDetails} from "../../../../declerations/server";
import {PaymentType} from "../../../../declerations/enums";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {useTask, useUser} from "../../../../contexts";
import {useGetUserWithSelectedAccountQuery} from "../../../../apis/requests/backoffice";
import {useGetUserQuery} from "../../../../apis/requests/user/getUser";
import LoadingCenter from "components/common/LoadingCenter";
import PopularBadge from "./PopularBadge";
import NiceModal from "@ebay/nice-modal-react";
import BlockedACHDialog from "./BlockedACHDialog";

interface PaymentTabPanelProps {
  onCardSubmit: (paymentType: PaymentType, paymentDetails: PaymentDetails, isChangePaymentMethod: boolean) => void;
  onAchSubmit: (paymentType: PaymentType, paymentDetails: PaymentDetails, isChangePaymentMethod: boolean) => void;
  setIsSupervisorView?: (i: boolean) => void;
  closePaymentDialogHandler?: () => void;
  submitLabelId?: string;
  termsAndConditionsId?: string;
  termsAndConditionsTextId?: string;
  onDashboardDialog?: boolean;
  currAccountId?: string;
  sx?: SxProps<Theme>;
}
const PaymentTabPanel: FunctionComponent<PaymentTabPanelProps> = ({
  onCardSubmit,
  onAchSubmit,
  submitLabelId,
  termsAndConditionsId,
  termsAndConditionsTextId,
  onDashboardDialog = false,
  setIsSupervisorView,
  closePaymentDialogHandler,
}: PaymentTabPanelProps) => {
  const {getUserId} = useTask();
  const userId = getUserId();
  const {isAgent} = useUser();
  const {data: user} = useGetUserQuery({});
  const {data: userDetails} = useGetUserWithSelectedAccountQuery({userId}, {enabled: !!userId});

  const achLabelId = useMemo((): string => {
    const labelId = "ach";
    const labelIdRequiresApproval = labelId + "_requires_approval";
    if (isAgent && userDetails) {
      if (!userDetails?.ach_allowed) return labelIdRequiresApproval;
      return labelId;
    }
    return labelId;
  }, [user, isAgent, userDetails]);

  const handleAchClick = useCallback(
    (setDebitActive: (id: string) => void) => {
      const isAchBlocked = isAgent ? userDetails && !userDetails.ach_allowed : user && !user.ach_allowed;
      if (isAchBlocked) {
        setDebitActive("debitCard");
        NiceModal.show(BlockedACHDialog, {
          setAchActiveTab: () => setDebitActive("ach"),
          secondaryButtonText: isAgent ? "approved_by_supervisor" : "contact_us",
          isAgent,
          setIsSupervisorView,
          closePaymentDialogHandler,
        });
      }
    },
    [user, userDetails]
  );

  const tabs = useMemo<TabInfo[]>(
    () => [
      {
        id: "ach",
        label: achLabelId,
        icon: isAgent || !user?.ach_allowed ? undefined : PopularBadge,
        onClick: handleAchClick,
      },
      {id: "debitCard", label: "debit"},
    ],
    [user, achLabelId]
  );

  const primaryEmail: string | undefined = useMemo(() => {
    if (isAgent && userDetails?.representation) {
      return userDetails?.representation.email || undefined;
    } else if (user?.represented) {
      return user.representation_email || undefined;
    }
    return user?.email || userDetails?.email;
  }, [userDetails, user]);

  if (userId && !userDetails) {
    return <LoadingCenter />;
  }

  return (
    <TabPanel
      tabs={tabs}
      addToHistory={false}
      defaultTabId={user?.ach_allowed ? "ach" : "debitCard"}
      saveActiveTab={false}
      onDashboardDialog={onDashboardDialog}
      iconPosition={"end"}
    >
      <AchPaymentForm
        onAchSubmit={onAchSubmit}
        submitLabelId={submitLabelId}
        termsAndConditionsId={termsAndConditionsId}
        termsAndConditionsTextId={termsAndConditionsTextId}
        isChangePaymentMethod={onDashboardDialog}
        primaryEmail={primaryEmail}
      />
      <CreditCardPaymentForm
        type={"DebitCard"}
        onCardSubmit={onCardSubmit}
        submitLabelId={submitLabelId}
        termsAndConditionsId={termsAndConditionsId}
        termsAndConditionsTextId={termsAndConditionsTextId}
        isChangePaymentMethod={onDashboardDialog}
        primaryEmail={primaryEmail}
      />
    </TabPanel>
  );
};
export default PaymentTabPanel;
