import React, {FunctionComponent, useEffect} from "react";
import NiceModal, {NiceModalHocProps} from "@ebay/nice-modal-react";
import {Container, ImgTermsAndConditions, Text, Colors, Dialog} from "../../../../bounce-ui/consumer";
import {Pages} from "../../../../bi-events/enum";
import {useAmplitude} from "../../../../contexts";

export type TermsAndConditionsDialogProps = {
  onConfirm?: () => void;
  onCancel?: () => void;
  termsAndConditionsTextId?: string;
};

const TermsAndConditionsDialog: FunctionComponent<TermsAndConditionsDialogProps & NiceModalHocProps> = NiceModal.create(
  ({onConfirm, onCancel, termsAndConditionsTextId}) => {
    const {pageViewEvent} = useAmplitude();

    useEffect(() => {
      pageViewEvent({page: Pages.T_C});
    }, []);

    return (
      <Dialog image={<ImgTermsAndConditions />} title={"Terms & Conditions"} buttonText={"Close"}>
        <Container
          sx={{
            width: "300px",
            gap: "20px",
          }}
        >
          <Text
            size={12}
            sx={{
              color: Colors.grey._800,
              whiteSpace: "break-spaces",
            }}
          >
            {termsAndConditionsTextId}
          </Text>
        </Container>
      </Dialog>
    );
  }
);

export default TermsAndConditionsDialog;
