import {axiosInstance} from "../../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseGeneratorVariable, ImportResponse} from "./utils";

export const generateWashReport = async (variables: BaseGeneratorVariable): Promise<ImportResponse> => {
  let url = `/backoffice/admin/wash/generate/${variables.reportType}`;
  if (!!variables.batch) {
    url += `?batch=${variables.batch}`;
  }
  const result = await axiosInstance.post<string, AxiosResponse<ImportResponse>>(url);

  return result.data;
};
