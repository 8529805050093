import {axiosInstance} from "../../../ServerApiManager";
import {AxiosResponse} from "axios";

type Variables = {
  lenderFileId: string;
};

type DownloadLink = {
  url: string;
  file_name: string;
};
export const downloadLenderFile = async (variables: Variables): Promise<void> => {
  const result = await axiosInstance.get<DownloadLink, AxiosResponse<DownloadLink>>(
    `/backoffice/admin/lender-files/download-link/${variables.lenderFileId}`
  );
  const link = document.createElement("a");
  link.href = result.data.url;
  link.download = result.data.file_name;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
