import {Colors} from "../bounce-ui/colors";

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    neutral: string;
  }
  interface TypeText {
    label: string;
    labelLight: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}

// SETUP COLORS
const PRIMARY = {
  lighter: Colors.primary._100,
  light: Colors.primary._200,
  main: Colors.primary._800,
  dark: Colors.primary._900,
  darker: Colors.primary._1000,
};
const SECONDARY = {
  lighter: Colors.primary._100,
  light: Colors.primary._100,
  main: Colors.primary._100,
  dark: Colors.primary._200,
  darker: Colors.primary._300,
};
const INFO = {
  lighter: Colors.grey._100,
  light: Colors.grey._200,
  main: Colors.grey._1000,
  dark: Colors.grey._1000,
  darker: Colors.grey._1000,
};
const SUCCESS = {
  lighter: Colors.green._100,
  light: Colors.green._200,
  main: Colors.green._800,
  dark: Colors.green._900,
  darker: Colors.green._1000,
};
const WARNING = {
  lighter: Colors.orange._100,
  light: Colors.orange._200,
  main: Colors.orange._700,
  dark: Colors.orange._900,
  darker: Colors.orange._1000,
};
const ERROR = {
  lighter: Colors.red._100,
  light: Colors.red._200,
  main: Colors.red._900,
  dark: Colors.red._1000,
  darker: Colors.red._1000,
};

const palette = {
  light: {
    common: {black: "#000", white: "#fff"},
    primary: {...PRIMARY, contrastText: "#fff"},
    secondary: {...SECONDARY, contrastText: "#fff"},
    info: {...INFO, contrastText: "#fff"},
    success: {...SUCCESS, contrastText: "#fff"},
    warning: {...WARNING, contrastText: "#fff"},
    error: {...ERROR, contrastText: "#fff"},
    divider: Colors.grey._200,
    action: {
      hover: Colors.grey._100,
      selected: Colors.grey._100,
      disabled: Colors.grey._600,
      disabledBackground: Colors.grey._100,
      focus: Colors.grey._100,
      active: Colors.grey._600,
    },
    text: {
      primary: Colors.grey._1000,
      secondary: "#4A90E2",
      disabled: Colors.grey._700,
      label: Colors.grey._900,
      labelLight: "#1b2653",
    },
    background: {paper: "#fff", default: "#fff", neutral: "#fff"},
  },
};

export default palette;
