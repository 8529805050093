import React, {FunctionComponent, ReactNode} from "react";
import {Dialog as MuiDialog} from "@mui/material";
import {useModal} from "@ebay/nice-modal-react";
import InputUtils from "../../../helpers/InputUtils";
import {CloseDialogButton} from "../CloseDialogButton";
import {Container} from "../Container";
import {Text} from "../Text";
import useIsMobile from "../../../helpers/useIsMobile";

export type DialogType = {
  title: string;
  image?: ReactNode;
  onClose?: () => void;
  alignCenter?: boolean;
  children?: ReactNode;
};

export const CustomDialog: FunctionComponent<DialogType> = ({title, image, onClose, alignCenter = false, children}) => {
  const modal = useModal();
  const isMobile = useIsMobile();
  return (
    <MuiDialog
      open={modal.visible}
      TransitionProps={{
        onExited: () => {
          onClose?.();
          modal.remove();
        },
      }}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "16px",
          padding: isMobile ? "44px 24px 24px 24px" : "44px",
          gap: "24px",
          "&.MuiPaper-elevation": {
            margin: "12px",
          },
        },
      }}
    >
      <CloseDialogButton onClose={modal.hide} />
      <Container sx={{gap: "24px", width: "300px", alignItems: alignCenter ? "center" : "start"}}>
        {image}
        <Text variant={"h2"} size={24}>
          {InputUtils.prettierSnakeCaseSentence(title)}
        </Text>
        {children}
      </Container>
    </MuiDialog>
  );
};
