import React, {FunctionComponent, ReactNode} from "react";
import {Dialog as MuiDialog} from "@mui/material";
import {useModal} from "@ebay/nice-modal-react";
import {Button, CloseDialogButton, Container, Text} from "bounce-ui/backoffice";

export type DialogType = {
  title: string;
  onClick?: () => void;
  onClose?: () => void;
  buttonText?: string;
  disabled?: boolean;
  autoClose?: boolean;
  width?: string;
  children?: ReactNode;
};

export const Dialog: FunctionComponent<DialogType> = ({
  title,
  buttonText = "Confirm",
  onClick,
  onClose,
  disabled = false,
  width,
  autoClose = true,
  children,
}) => {
  const modal = useModal();
  const onClickModal = async () => {
    onClick?.();
    if (autoClose) {
      modal.resolve(true);
      modal.hide();
    }
  };

  return (
    <MuiDialog
      open={modal.visible}
      TransitionProps={{
        onExited: () => {
          onClose?.();
          modal.remove();
        },
      }}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "16px",
          padding: "44px",
        },
      }}
    >
      <CloseDialogButton onClose={modal.hide} />
      <Container sx={{gap: "24px", width: width || "300px"}}>
        <Container fullWidth sx={{alignItems: "start"}}>
          <Text variant={"h2"} size={24}>
            {title}
          </Text>
        </Container>
        {children}
        <Container fullWidth sx={{gap: "8px"}}>
          <Button onClick={onClickModal} disabled={disabled} longWidth>
            {buttonText}
          </Button>
        </Container>
      </Container>
    </MuiDialog>
  );
};
