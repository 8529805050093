import {FormattedMessage} from "react-intl";
import React, {FunctionComponent, ReactNode, useCallback, useMemo, useState} from "react";
import {Checkbox, FormControlLabel} from "@mui/material";
import NiceModal, {NiceModalHocProps, useModal} from "@ebay/nice-modal-react";
import {Container, ImgCancelPlan, Dialog} from "../../../../bounce-ui/consumer";
import {useDeactivatePaymentPlanForAccount} from "../../../../apis/requests/plans";
import {formatCurrency} from "../../../../helpers/formatNumber";
import {useUser, useTask, useAmplitude} from "../../../../contexts";
import {useGetAccount} from "../../../../apis/requests/user/getAccount";
import {Pages} from "../../../../bi-events/enum";
import {urls} from "../../../../routes/urls";
import {useNavigate} from "react-router-dom";

export type CancelPaymentPlanDialogProps = {
  accountId: string;
  activePaymentPlanId: string;
};
const CancelPaymentPlanDialog: FunctionComponent<CancelPaymentPlanDialogProps & NiceModalHocProps> = NiceModal.create(
  ({accountId, activePaymentPlanId}) => {
    const {pageClickEvent} = useAmplitude();
    const navigate = useNavigate();
    const {data: account} = useGetAccount({accountId}, {enabled: !!accountId});
    const modal = useModal();
    const {isAgent} = useUser();
    const {getUserId} = useTask();
    const userId = getUserId();
    const originalBalance = useMemo(() => account?.total_balance, [account]);
    const checkboxes = useMemo<{id: string; label: ReactNode}[]>(
      () => [
        {id: "1", label: <FormattedMessage id={"understandWillCancelDiscount"} />},
        {
          id: "2",
          label: originalBalance && (
            <FormattedMessage id={"understandAmountWillBeReverted"} values={{cost: formatCurrency(originalBalance)}} />
          ),
        },
        {id: "3", label: <FormattedMessage id={"understandReportToCreditBureaus"} />},
      ],
      [originalBalance]
    );

    const [checkedIds, setCheckedIds] = useState<string[]>([]);

    const allChecked = useMemo(() => checkedIds.length === checkboxes.length, [checkedIds, checkboxes]);

    const checkHandler = useCallback(
      (id: string, checked: boolean): void => {
        setCheckedIds((ids) => (checked ? [...ids, id] : ids.filter((i) => i !== id)));
      },
      [setCheckedIds]
    );

    const deactivateState = useDeactivatePaymentPlanForAccount({refetchType: "all"});

    const cancelPaymentPlanHandler = useCallback(async (): Promise<void> => {
      if (activePaymentPlanId) {
        try {
          const result = await deactivateState.mutateAsync({
            account_id: accountId,
            user_id: userId,
            isAgent,
            payment_plan_id: activePaymentPlanId,
          });
          if (result) {
            modal.resolve(true);
            modal.hide();
          }
        } catch (e) {
        } finally {
          pageClickEvent({page: Pages.CANCEL_PLAN, cta: "confirm_cancel", data: {consent: true}});
          if (!isAgent) {
            navigate(urls.accounts.url);
          }
        }
      }
    }, [activePaymentPlanId]);

    const handleLeavePlan = useCallback((): void => {
      pageClickEvent({page: Pages.CANCEL_PLAN, cta: "leave"});
    }, []);

    return (
      <Dialog
        image={<ImgCancelPlan />}
        title={"Cancel my plan"}
        buttonText={"Confirm and cancel"}
        onClick={cancelPaymentPlanHandler}
        secondaryButtonText={"Keep my plan"}
        onClickSecondary={handleLeavePlan}
        isError
        disabled={!allChecked}
      >
        <Container fullWidth sx={{gap: "8px", alignItems: "start", overflow: "auto"}}>
          {checkboxes.map((checkbox) => (
            <FormControlLabel
              control={
                <Checkbox
                  color={"error"}
                  checked={checkedIds.includes(checkbox.id)}
                  onChange={(event, checked) => checkHandler(checkbox.id, checked)}
                  sx={{margin: "2px 10px", width: "20px", height: "20px"}}
                />
              }
              label={checkbox.label}
              key={checkbox.id}
              sx={{alignItems: "start"}}
            />
          ))}
        </Container>
      </Dialog>
    );
  }
);

export default CancelPaymentPlanDialog;
