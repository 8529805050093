// eslint-disable-next-line import/no-anonymous-default-export
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  proceed: "Proceed",
  emailAddress: "Email address",
  phoneNumber: "Phone number",
  loginTitle: "Hi {username}",
  loginToYourAccount: "Log in to your account",
  hiUsername: "Hi {username},",
  loginSubtitle: "Please enter the following\ninformation to login",
  loginTermsDeprecated:
    "By continuing, you agree to the {privacyPolicy}. Standard text messaging rates may apply. California residents can learn more about the personal information we collect by visiting {californiaDisclosures} and {privacyPolicy}.",
  forYourSecurity: "For your security, we need to make sure that it’s really you.",
  ssn: "ssn",
  enterYourContactInfo: "Enter your contact info",
  wellSendYou: "We’ll send a you a verification code via text or email",
  loginByContact: "Please enter your phone number or email to receive a verification code",
  enterLast4ofYourSSN: "Enter last 4 of your SSN",
  labelContact: "Phone number or email",
  loginTerms: "By continuing, you agree to the ",
  yourDataIsSafe: "Your data is safe",
  neverWorryAboutYourDetails:
    "Never worry about your details being shared or compromised on our secure website. All data is sent over an encrypted SSL connection. We take your privacy very seriously.",
  weKeepYourDataProtected: "We keep your data protected",
  yourPrivacyAndSecurity:
    "Your privacy and security is our top priority. We use industry leading SSL encryption to keep your details private, protected and safeguarded.",
  privacyPolicy: "Privacy Policy",
  termsOfService: "Terms of Service",
  termsAndConditions: "Terms & Conditions",
  californiaDisclosures: "California Disclosures",
  verifyTitle: "Verify it's you",
  enterTheCode: "Enter the code",
  verifyByContact: "Where do you want to receive your verification code?",
  verifyByReferenceNumber: "Please enter your Bounce AI 10 digits reference number",
  verifySwitchAuthMode: "Prefer to be ",
  identifyWithContact: "identified using a phone number or email? ",
  verifiedWithReferenceNumber: "verified using your reference number? ",
  enterCode: "Please enter the 4-digits code sent to ",
  switchHere: "Switch here",
  contactMode: "verified using your reference number",
  bounceAIMode: "identified using a phone number or email",
  findBounceAINumber: "Shown on any messages we send as: ",
  bounceAIStructure: "BA-XXXXXXXXXX",
  verifyCode: "Verify Code",
  verifyAccount: "Verify Account",
  verifyPhone: "Verify Phone",
  verifyEmail: "Verify Email",
  verifyFailed: "Failed to verify your contact details",
  verifyCodeSubtitle: "Enter the 4-digit code we sent to {contactDetail}",
  didNotReceiveTheCode: "Didn't receive the code?",
  resendCode: "Resend Code",
  resendCodeSuccess: "A new code was sent",
  incorrectVerificationCode: "Incorrect verification code",
  enterYourVerificationCodeAgain: "Enter your verification code again",
  ok: "OK",
  contactMethods: "Contact Methods",
  contactDetails: "Contact Details",
  yourPreferredContactMethod: "What is your preferred contact method?",
  pleaseUpdateYourContactDetails: "Please update your contact details",
  approve: "Approve",
  contactMethodsApproval:
    "By approving, you consent to being contacted by {collectionLabel} via the selected communication channels regarding your account.",
  selectContactMethodToContinue: "Select contact method to continue",
  text: "Text",
  phoneCall: "Phone Call",
  UpdateContactInfo: "Update contact info",
  sendEmail: "Send an email",
  sendMessage: "Send a message",
  makeACall: "Make a call",
  sendALetter: "Send a letter",
  balance: "Balance",
  accountInformation: "Account Information",
  OurInformationShows: "Our information shows",
  viewPaymentOptions: "View payment options",
  continueWithAPlan: "Continue with a Plan",
  iWantToDisputeDebt: "I want to dispute this debt",
  iWantToDisputeDebtBecause: "I want to dispute this debt because I think:",
  seeExpandedInformation: "See expanded information",
  product: "Product",
  accountNumber: "Account number",
  referenceNumber: "Reference Number",
  referenceNumberLow: "Reference number",
  balanceDue: "Balance Due",
  dispute: "Dispute",
  payment: "Payment",
  paymentPlans: "Payment Plans",
  selectDisputeToContinue: "Select dispute reason to continue",
  thisIsNotMyDebt: "This is not my debt.",
  amountIsWrong: "The amount is wrong.",
  other: "Other",
  descriptionAndFileAdded: "Description & file added",
  additionalInformationAdded: "Additional information added",
  describeOrAttachAdditionalInformation: "please describe or attach additional information",
  enterTextHere: "Enter Text here",
  disputeSuccess: "Dispute filed successfully",
  backHome: "Back Home",
  backToHomepage: "Back to homepage",
  thankYou: "Thank You!",
  disputeFiledSuccessfully: "Dispute filed successfully,\nwe will review it and contact you soon",
  loadingPlans: "Loading plans.",
  chooseYourPlan: "Choose your plan",
  chooseYourPlanSubtitle:
    "All options have no fees or interest. Payments plan can be freeze, adjust or cancel anytime.",
  fastest: "Fastest",
  flexible: "Flexible",
  bestOfBoth: "Best of Both",
  choosePlan: "Choose Plan",
  buildPlan: "Build Plan",
  buildYourPlan: "Build your plan",
  wizardCustomizePlan: "Use the wizard below to customize your payment plan",
  pickAStartDay: "Pick a Start Day",
  planStartDate: "Plan Start Date",
  continueToSummary: "Continue To Summary",
  installmentSize: "Installment Size",
  installmentsPlan: "Installments Plan",
  Installments: "Installments",
  interimPlan: "Temporary Relief Program",
  customPlan: "Custom Plan",
  numberOfInstallments: "Number of Installments",
  oneTime: "One-Time",
  paymentSuccess: "Payment Success",
  paymentSuccessful: "Payment Successful!",
  calendarReminders: "Calendar reminders",
  chosenPlanAmount: "Chosen plan amount",
  newTotal: "New total",
  amountDeducted: "Amount deducted",
  PleaseAllow2DaysPaymentToUpdate: "Please allow up to 2 days for payments to update in the system.",
  checkout: "Checkout",
  confirmation: "Confirmation",
  creditCard: "Credit",
  debitCard: "Debit",
  achPayment: "ACH Payment",
  ach: "ACH",
  ach_requires_approval: " ACH (requires approval)",
  debit: "Debit",
  card: "Card",
  popular: "Popular",
  inPaymentsOf: "in {paymentsCount} payments of {paymentAmount}",
  confirmPayment: "Confirm payment",
  nameOnCard: "Name on Card",
  cardNumber: "Card Number",
  emailAddressForReceipts: "Email Address for Receipts",
  cvv: "CVV",
  zipCode: "Zip Code",
  month: "Month",
  year: "Year",
  accountOwnerName: "Account Owner's Name",
  bankAccountNumber: "Bank Account Number",
  bankRoutingNumber: "Bank Routing Number",
  bankAccountType: "Bank Account Type",
  paymentSummary: "Payment Summary",
  chooseHowToPay: "Choose how to pay",
  selectPlanToContinue: "Select plan to continue",
  confirmPaymentPlan: "Confirm payment plan",
  totalPlanAmount: "Total Plan Amount",
  paymentFrequency: "Payment Frequency",
  arrangementStartDate: "Arrangement Start Date",
  arrangementEndDate: "Arrangement End Date",
  paymentFailed: "Payment Failed",
  ooops: "ooops!",
  yourTransactionCannotBeCompleted: "Your transaction cannot be completed",
  takeMeBackToCheckout: "Take me back to checkout",
  showLegalNotice: "Show Legal Notice",
  legalNotice: "Legal Notice",
  done: "Done",
  iHaveReadAndUnderstood: "I have read and understand",
  confirmCheckboxToContinue: "Confirm checkbox to continue",
  enterYourSsnLastDigits: "Enter your last 4 digits of SSN",
  chooseYourVerificationChannel: "Choose your verification channel",
  contactSupport: "Contact support",
  howCanWeHelp: "How can we help?",
  sendUsMessage: "Send us a message below, and we will contact you shortly.",
  typeYourMessageHere: "Type your message here",
  messageSentSuccessfully: "Message sent successfully",
  enrollInAutoPay: "Enroll in AutoPay",
  enrollNow: "Enroll Now",
  enroll: "Enroll",
  continueWithoutAutopay: "Continue Without AutoPay",
  areYouSureThatYouDontWantToEnroll: "Are you sure that you don’t want to enroll?",
  convinceEnrollAutopay:
    "AutoPay is a convenient option that helps ensure your payments are made on time and frees you up from having to remember when to pay.  There is no cost for this service and you can change your enrollment status at any time.  Would you like to enroll in AutoPay?",
  yesIWantToEnroll: "Yes, I want to enroll",
  noIllMakeEachPayment: "No, I’ll make each payment",
  enrollInAutoPayDescription:
    "We offer our customers the ability to enroll in automatic payments as a complimentary service to ensure that payments can be made conveniently and within the agreed timeframes. This service is optional but we highly recommend that customers take advantage since there are no fees or costs to enroll.",
  paymentTermsAndConditions:
    "By checking this box, I agree to the {termsAndConditions} and authorize Bounce AI to debit my account according to my plan and email me confirmations, reminders, and receipts. I understand I can update my preferences or withdraw this authorization at any time.",
  shortPaymentTermsAndConditions:
    "I accept the {termsAndConditions} and authorize {collectionLabel} to electronically debit my designated account as described above.",
  singlePaymentTermsAndConditions:
    "By checking this box, you confirm that you have read and agree to the {termsAndConditions} and authorize {collectionLabel} to electronically debit your designated account as described above.",
  total: "Total",
  payments: "Payments",
  starting: "Starting",
  ending: "Ending",
  payFirstPayment: "Pay First Payment",
  manualPaymentExplanation:
    "You have elected not to enroll in automatic payments and so you will be responsible for making each payment before the scheduled due dates.  Payments can either be made electronically using this app or via check or money order by sending them to the following address:",
  unknown: "Unknown",
  resolveYourEntireBalance: "Resolve your entire outstanding balance",
  payCustomAmount: "Pay a custom amount",
  invalidAmount: "Invalid amount",
  enterValidAmount: "Please enter a valid amount",
  retryAreYouSureDialog: "Are you sure you want to try to pay this installment again? This might incur cost",
  retryInstallmentAnyway: "Retry Anyway",
  error: "Error",
  login: "Login",
  contactUs: "Contact Us",
  crud: "CRUD",
  table: "Table",
  details: "Details",
  account: "Account",
  operators: "Operators",
  operatorDetails: "Operator Details",
  roles: "Roles",
  roleDetails: "Role Details",
  data: "Data",
  reports: "Reports",
  reportDetails: "Report Details",
  dashboard: "Dashboard",
  example: "Example",
  exampleDetails: "Example Details",
  exampleNested: "Example Nested",
  exampleNestedDetails: "Example Nested Details",
  email: "Email",
  phoneNumberTitle: "Phone Number",
  password: "Password",
  fullName: "Full Name",
  requiredField: "Required field",
  invalidValue: "Invalid value",
  emailInvalid: "Invalid email",
  phoneInvalid: "Invalid Phone",
  dateInvalid: "Date invalid",
  typeToConfirm: 'Type "{confirm}" to confirm.',
  cancel: "Cancel",
  continue: "Continue",
  lastEdit: "Last Edit",
  of: "of",
  yes: "Yes",
  no: "No",
  notAvailable: "N/A",
  remove: "Remove",
  actions: "Actions",
  preview: "Preview",
  download: "Download",
  noResultsFound: "No results found",
  result: "Result",
  errorRetrievingData: "Index retrieving data",
  sendUsAnEmail: "Send us an email to:",
  id: "ID",
  userAgent: "User Agent",
  messagingToken: "Messaging Token",
  hide: "Hide",
  hideThisForever: "Hide This Forever",
  send: "Send",
  close: "Close",
  submit: "Submit",
  open: "Open",
  openInNewTab: "Open in New Tab",
  create: "Create",
  delete: "Delete",
  archive: "Archive",
  share: "Share",
  update: "Update",
  duplicate: "Duplicate",
  restore: "Restore",
  publish: "Publish",
  custom: "Custom",
  Custom: "Custom",
  reason: "Reason",
  moreInfo: "More Info",
  maximumValuesReached: "Maximum values reached",
  copyToClipboardSuccess: "Copied to clipboard successfully.",
  copyToClipboardFailed: "Copy to clipboard failed.",
  loading: "Loading...",
  createNew: "Create new...",
  pageNotFoundError: "Sorry, page not found!",
  pageNotFoundErrorExplanation:
    "Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling or contact support.",
  goToHome: "Go to home",
  downForMaintenance: "Down For Maintenance",
  downForMaintenanceExplanation:
    "The system is down for maintenance. We are sorry for the inconvenience. Contact us anytime at {supportEmail}.",
  networkConnectionError: "Network Connection Index",
  networkConnectionErrorExplanation:
    "Internet connection failed. Please check your internet connection or contact us anytime at {supportEmail}.",
  darkMode: "Dark Mode",
  lightMode: "Light Mode",
  confirmDeleteItem: "Are you sure you want to delete this item?",
  confirmDeleteSelectedItems: "Are you sure you want to delete the selected items?",
  noSelectedItems: "There are no selected items. Please select items to preform action.",
  updateSelectedItems: "Update Selected Items",
  import: "Import",
  someItemsFailedToDelete: "Some items failed to delete.",
  allItemsFailedToDelete: "All items failed to delete.",
  someItemsFailedToUpdate: "Some items failed to update.",
  allItemsFailedToUpdate: "All items failed to update.",
  noItemIsSelected: "No item is selected.",
  supportId: "Support ID",
  supportInformation: "Information for support",
  errorId: "Index ID",
  errorKey: "Index key",
  supportErrorAnythingToAdd: "Additional information you would like to add",
  supportErrorGetBack: "Thank you! We will get back to you as soon as possible.",
  technicalInformation: "Technical Information",
  search: "Search",
  select: "Select",
  dropOrSelectFile: "Drop or Select file",
  dropFilesHereOrClick: "Drop files here or click",
  getYourSheetFromExcel: "Get your sheet from Excel / CSV",
  youCanUseAnyTemplate: "You can use any template you would like",
  next: "Next",
  back: "Back",
  skip: "Skip",
  finish: "Finish",
  viewAll: "View All",
  showingRows: "Showing {visibleRows} of {totalRows} total rows. All {totalRows} rows will be imported.",
  showingRowsMax:
    "Showing {visibleRows} of {totalRows} total rows. Please note! You have passed the maximum allowed rows. The maximum of {maxRows} rows will be imported.",
  importColumnSelection: 'Select your "{columnTitle}" column?',
  importColumnSelectionExplanation: 'This will become the "{columnTitle}" of each item.',
  noItemsToImport: "No items to import.",
  importInvalidColumnValues: "Are you sure correct column is selected? It contains invalid values.",
  uploadingDataNow: "Uploading your data now...",
  dataImportedSuccessfully: "{successCount} of {totalCount} rows imported successfully!",
  dataImportFailed: "Could not import data. Verify your selected columns.",
  importCheckInformation: "Check Uploaded Information",
  importCheckInformationExplanation: "The process cannot be undone after this step.",
  processMayTakeSeveralMinutes: "This process may take several minutes.",
  clearFilters: "Clear Filters",
  hiWelcomeBack: "Hi, Welcome Back",
  doNotHaveAnAccount: "Don’t have an account?",
  admin: "Admin",
  general: "General",
  changePassword: "Change Password",
  oldPassword: "Old Password",
  newPassword: "New Password",
  passwordChangedSuccessfully: "Password changed successfully",
  grants: "Grants",
  permissionDenied: "Permission Denied",
  PermissionToAccessPageDenied: "You do not have permission to access this page",
  generate: "Generate",
  passwordProtectedPdf: "Password Protected PDF",
  insertPdfPassword: "Insert the PDF password to view file.",
  showingRowsPreview: "Showing {visibleRows} of {totalRows} total rows.",
  unsupportedDashboardWidget: "Unsupported dashboard widget.",
  yourProgress: "Your progress",
  getStartedNow: "Get Started Now",
  numberOfMinutes: "{minutes} Minutes",
  tryAgain: "Try Again",
  nextStep: "Next Step",
  completed: "Completed!",
  yay: "Yay!",
  confirmMinimizeWizard: "Are you sure you want to minimize? You will still be able to access it through the top menu.",
  minimize: "Minimize",
  finishedWizard:
    "You have finished the tutorial. Amazing job! If you need additional help we are always here for you.",
  great: "Great!",
  notes: "Notes",
  typeNote: "Type a note",
  networkError: "Network Index",
  networkConnectionLost: "Network connection is lost.",
  invalidFieldUpload: "Cannot upload selected file.",
  invalidFieldUploadTooLarge: "Cannot upload selected file. File size too large.",
  invalidFieldUploadTooSmall: "Cannot upload selected file. File size too small.",
  invalidFieldUploadTooManyFiles: "Cannot upload selected file. Too many files selected.",
  invalidFieldUploadInvalidType: "Cannot upload selected file. Invalid file type.",
  failedLoadPdfFile: "Failed to load PDF file.",
  failedLoadPdfPage: "Failed to load the page.",
  cannotOpenPdfPassword: "Could not open PDF without password.",
  fileTypePreviewNotAvailable: ".{fileType} preview is not available. Download file to continue.",
  videoPlaybackNotSupported: "Video playback is not supported by your browser.",
  audioPlaybackNotSupported: "Audio playback is not supported by your browser.",
  creationTime: "Creation Time",
  status: "Status",
  description: "Description",
  name: "Name",
  blocked: "Blocked",
  lender: "Lender",
  autoPay: "AutoPay",
  balancePaid: "Balance Paid",
  totalAmountPaid: "Total amount paid",
  totalAmount: "Total amount",
  remainingBalance: "Remaining Balance",
  cancellationDate: "Cancellation Date",
  installment: "installment",
  hardshipPlan: "Hardship plan",
  showLess: "Show less",
  showAll: "Show all",
  nextPayment: "Next payment",
  totalPaymentPlanAmount: "Total Payment Plan Amount",
  paymentMethodLast4Digits: "Payment Method Last 4 Digits",
  regarding: "Regarding",
  installmentAmount: "Installment Amount",
  earlyPayoff: "Early payoff",
  installmentDate: "Installment Date",
  amount: "Amount",
  planProperties: "Plan Properties",
  paymentSchedule: "Payment Schedule",
  paymentMethod: "Payment Method",
  paymentNumber: "Payment No.",
  billingName: "Billing Name",
  updatedAt: "Updated At",
  dueDate: "Due Date",
  dueOn: "due on",
  paidOn: "Paid on {date}",
  paymentInProgress: "Payment in progress",
  overdue: "Overdue",
  scheduledPayment: "Scheduled Payment",
  paid: "Paid",
  late: "Late",
  payNextPayment: "Pay Next Payment",
  payNow: "Pay now",
  resolveNow: "Resolve now",
  updatePayment: "Update payment",
  managePayment: "Manage payment",
  saveAndUpdate: "Save and update",
  updateAndResolve: "Update and resolve",
  resolveLatePayment: "Resolve late payment",
  thisReliefProgramEnds: "This relief program ends in {days} days",
  switchToFullPlan: "Switch to a full plan",
  cancelPaymentPlan: "Cancel My Plan",
  understandWillCancelDiscount: "I understand that this will cancel all of my discounts",
  understandAmountWillBeReverted: "I understand that the amount due will be reverted to {cost}.",
  understandReportToCreditBureaus: "I understand that Bounce AI will have to report this action to the Credit Bureaus.",
  thingsAreGettingHeavy: "Things are getting heavy?",
  needTimeOrMoreAffordableOptions: "Need time or more affordable options?",
  ourTemporaryRelief: "Our 3-month Temporary Relief Program is here to help.",
  notOnlyWillYouBenefit:
    "Not only will you benefit from smaller, more manageable payments, but you’ll also avoid negative reports on your credit.",
  talkToOneOfOurExpertsToday: "Talk to one of our experts today to see if you’re eligible.",
  talkToAnExpert: "Talk to an expert",
  delayUpcomingPayment: "Delay Upcoming Payment",
  myRepaymentPlan: "My Repayment Plan",
  pay: "Pay",
  cancelMyPlan: "Cancel my plan",
  cancelAutoPay: "Cancel auto pay",
  areYouSureYouWantToCancelAutoPay: "Are you sure you want to cancel auto pay?",
  autoPayIsCanceledSuccessfully: "Auto pay is canceled successfully",
  failedToCancelAutoPay: "failed to cancel auto pay",
  saveChanges: "Save changes",
  communicateAnyway: "Communicate Anyway",
  areYouSure: "Are You Sure?",
  clickHereWithTranslation: "Click here with translation",
  stillWantTo: "I still want to ",
  iWantToCancelThePlan: "I want to cancel the plan",
  paymentDelayedSuccessfully: "Payment delayed successfully",
  cancelKeepPlan: "Cancel, Keep Plan",
  confirmAndCancel: "Confirm and cancel",
  autoPayActivatedSuccessfully: "Auto pay activated successfully",
  failedToActivateAutoPay: "Failed to activate autopay",
  saveTimeWithAutoPay: "Save time with AutoPay",
  paymentMethodForAutoPay: "Payment Method for AutoPay",
  activatingAutomaticPaymentRegularRepayment:
    "Automatically pay your scheduled repayments instead of manually paying each time one comes up.",
  activateAutoPay: "Activate AutoPay",
  makePayment: "Make a Payment",
  success: "Success!",
  yourOpinionMatters: "Your opinion matters",
  haveAMinuteToSpare: "Have a minute to spare? We’d love if you left a quick review at {link}",
  leaveAReview: "Leave a review",
  yourPaymentWasProcessedSuccessfully: "Your payment was processed successfully",
  saveAndFinish: "Save and Finish",
  payUpcomingPayment: "Pay Upcoming Payment",
  managePaymentMethod: "Manage Payment Method",
  addPaymentMethod: "Add Payment Method for AutoPay",
  changedTo: "Changed to ",
  paymentMethodChangedSuccessfully: "Changes to your payment method were saved",
  paymentMethodChangedFailed: "Failed to change your payment method",
  updateInstallmentDateSuccessfully: "Update installment date successfully",
  accountInActivePP:
    "Our records show that you are enrolled in a payment plan. You can make payments using the app by clicking the “Make a One-Time Payment” button. If you are willing to make changes in your plan, or have any questions or concerns, please contact us at Tel. 800.910.8180, or by using the “Contact Us” button below.",
  accountManagedByJanuary:
    "Your account is managed by January, please refer to their customer portal to view your options on https://borrower.january.com/login",
  accountManagedByExternalCollector:
    "Your account is managed by {externalCollector}, please refer to their customer portal to view your options",
  paymentsUpdatesDays: "Track all your payments below. New payments can take up to 3 days to update in the schedule.",
  planSummaryTerms:
    "This summary assumes that all payments will be made on time per the plan schedule. Late or dishonored payments could result in additional interest charges and fees, to the extent expressly authorized by your contract or permitted by law.",
  enumBankAccountTypeUnknown: "Unknown",
  enumBankAccountTypePersonalChecking: "Personal Checking",
  enumBankAccountTypePersonalSavings: "Personal Savings",
  enumBankAccountTypeBusinessChecking: "Business Checking",
  searchPanel: "Search Panel",
  informationPanel: "Information Panel",
  creditorRecalled: "Creditor Recalled",
  complaint: "Complaint",
  bankruptcy: "Bankruptcy",
  fraudReported: "Fraud Reported",
  accountStatus: "Account Status",
  accountDetails: "Account Details",
  originalAccountNumber: "Original Account #",
  totalBalanceHeader: "Total Balance",
  flags: "Flags",
  ceaseAndDesist: "Cease And Desist",
  hearingDisability: "Hearing Disability",
  readingDisability: "Reading Disability",
  campaignActive: "Campaign Active",
  activeDispute: "Active Dispute",
  isChargedOff: "Is Charged Off",
  unsavedChanges: "Unsaved Changes",
  continueWithoutSavedChanges: "Continue without save changes",
  continueWithoutSavedChangesMessage: "You have unsaved changes, are you sure you want to continue?",
  agentSaveChangesMessage:
    "Changes may have effects in the system including automatic messages to consumers,\n are you sure you want to save your changed?",
  disputeThisDebt: "Dispute This Debt",
  originalDebt: "Original debt",
  waivedFees: "Fees waived",
  waivedInterest: "Interest waived",
  areYouSureYouWantToDeleteFiles: "Are you sure you want to delete the selected files?",
  areYouSureResendLetter: "Are you sure you want to resend the letter to the user?",
  areYouSureDeleteReport: "Are you sure you want to delete user's credit report statement?",
  areYouSureYouWantToVoidThePayment: "Are you sure you want to void this payment?",
  areYouSureYouWantToRefundThePayment: "Are you sure you want to refund this payment?",
  resendLetter: "Resend letter",
  brokenPoliceRulesString: "The police failed on the following rules:",
  areYouSureAbstractCommunication: "Are you sure you want to proceed?",
  enumPoliceRulesoutsideValidCommunicationTimes: "Communication outside business hours",
  enumPoliceRulesNotABusinessDay: "Communication date not a business day",
  enumPoliceRulesUserCommunicationPreferences: "Communication method deselected",
  enumPoliceRulesAllowToCommunicateWithUser: "User communication frozen",
  enumPoliceRulesNotInValidationPeriod: "User validation not received",
  enumPoliceRulesCheckMaxContacts: "User contacted in the last week",
  enumPoliceRulesCheckMaxContactsAttemptsInAWeek: "Over 7 in 7 communications",
  enumPoliceRulesCheckMaxContactsAttemptsInADay: "Date contains too many communication attempts",
  enumPoliceRulesPhoneNotReassigned: "Phone number invalid",
  enumPoliceRulesCheckPhoneValidity: "Phone number invalid",
  enumPoliceRulesNoEmailToNy: "Not allowed to email residents in New York",
  enumPoliceRulesNotFromUnallowedStates: "Not allowed to communicate with user's location",
  enumPoliceRulesAccountNotPurchased: "ACCOUNT NOT OWNED BY BOUNCE",
  filter: "Filter",
  activityType: "Activity type",
  doneBy: "Done by",
  filterByActivityType: "Filter by activity type",
  filterByPrincipal: "Filter by principal",
  noResults: "No results",
  deleteReport: "Delete report",
  copyLink: "Copy link to account",
  copiedLinkToAccountSuccessfully: "Copied link to account successfully",

  //Payment plan
  viewDetails: "View details",
  customizeAPlan: "Customize a plan",
  customizeYourPlan: "Customize your plan",
  oneTimePayment: "One-Time Payment",
  oneTimeAmount: "One-Time Amount",
  settleInFull: "Settle in full",
  payOverTime: "Pay over time",
  wantToMake: "I want to make a ",
  partialPayment: "partial payment",
  hardshipProgram: "Hardship program",
  cantAffordTheMinimumPayment: "Can't afford the minimum payment? ",
  exploreMore: "Explore more",
  chatWithAnExpert: "Chat with an expert",
  weUnderstandLifeUncertainties:
    "We understand life's uncertainties and know how unforeseen events like sudden job loss, long-term illness or relationship breakdown can change everything.",
  aHardshipProgramCanHelpYou: "A hardship program can help you:",
  payTheMinimum: "Pay the minimum, affordable amount for the first 3 or 6 months.",
  buildAFollowUpPlan: "Build a follow-up plan that works for you.",
  avoidANegativeListingOnYourCreditReport: "Avoid a negative listing on your credit report.",
  chooseHowOftenToPay: "Choose how often to pay",
  chooseFrequencyAgent: "How often the consumer want to pay?",
  setYourAmount: "Set your {frequency} amount",
  viewPlanSummary: "View plan summary",
  reviewYourPlan: "Review your plan",
  lastInstallment: "Last installment",
  firstInstallments: "First installments",
  remainingInstallments: "Remaining installments",
  totalBalance: "Total balance",
  confirm: "Confirm",
  chooseCustomizedPaymentAmountUser: "How much the consumer want to pay?",
  numberOfPayments: "Number Of Payments",
  paymentAmount: "Payment Amount",
  frequency: "Frequency",
  startDate: "Start Date",
  endDate: "End Date",
  firstPaymentDate: "First payment date",
  paymentDate: "Payment Date",
  monthly: "Monthly",
  biWeekly: "Bi-Weekly",
  weekly: "Weekly",
  firstAndFifteenth: "1st & 15th",
  asLowAs: "as low as",
  proceedToPayment: "Proceed to payment",
  setUpPlan: "Set up plan",
  payItNow: "Pay it now",
  manage: "Manage",
  resolve: "Resolve",
  adjustablePaymentDate: "Payment date",
  youCanChangeTheCurrentPaymentDate:
    "You can change the current payment date to pay this installment earlier or before the next payment.",
  confirmPlan: "Confirm plan",
  changePaymentDate: "Change payment date",
  pickADateBeforeYourNextPayment: "Pick a date before your next payment",
  whenDoYouWantToStart: "When do you want to start paying?",
  whenDoYouWantToPay: "When do you want to make this payment?",
  chooseTheAmount: "Choose the partial amount",
  setDate: "Set date",
  make: "Make ",
  payManually: "I'll pay manually",
  manualPayments: "manual payments",
  manualPaymentContent:
    "You selected to continue without AutoPay. This means you'll have to make each payment manually to maintain your payment plan. You can make manual payments using this app or by sending a check or money order to this address: ",
  bounceAI: "Bounce AI",
  bounceAddress: "2850 Clover Street, Suite 3 ",
  addressCity: "Pittsford, NY 14534",
  planSummary: "Plan Summary",
  paymentInfo: "Payment Info",
  paymentsOf: " payments of ",
  paymentOf: " payment of ",
  lastPaymentOf: "last payment of ",
  paymentOn: "Payment on ",
  choosePaymentMethod: "Choose a payment method",
  PlanOptions: "Plan options",
  presetPlan: "Build plan",
  customPaymentV2: "custom payment",
  congratulations: "Congratulations!",
  paymentSubmitted: "Payment Submitted",
  oneStepCloserToPutting: "You're one step closer to putting this debt behind you",
  yourPaymentWasSuccessfullySubmitted: "Your payment was successfully submitted!",
  debtIsAlmostBehind: "This debt is almost behind you! Processing may take up to 3-5 days.",
  youreOnFire: "You're on fire!",
  oneDownFewMoreToGo: "1 down, few more to go. Let’s put the next debt behind you",
  creditReportConsent:
    "I understand that late or failed payments can result in this payment plan being canceled and your debt ",
  beingReportedToCreditBureaus: "being reported to the credit bureaus.",
  viewPlanDetails: "View plan details",
  resolveNextDebt: "Resolve next debt",
  youCan: "You can ",
  viewYourCurrentPlanDetails: "view your current plan details",
  andResolveTheRestLater: " and resolve the rest later.",
  paymentTooltip: "This sets up automatic payments to make sure they are on time and according to your chosen plan.",
  continueWithAutoPay: "Continue with AutoPay ",
  instead: "instead",
  checkoutV2: "Checkout",
  manualPayment: "Manual payment",
  creditCardInvalid: "Your credit card number is invalid",
  partialAmount: "Partial amount",
  totalRemainingBalance: `(Remaining balance {totalBalance})`,
  totalBalanceValue: `(Total balance {totalBalance})`,
  interestAndFeesWaived: "Interest & Fees waived",
  tip: "Tip: ",
  mostUsersPick: "Most users pick a day they get paid",
  underTheLimit: "(under the limit)",
  exceededTheLimit: "(exceeds the limit)",
  singleInstallment: "Single Installment",
  remainderWillBeAdded: "(A remainder of under 10$ will be added to the plan's last installment)",
  typeToSearch: "Type to search",
  thisAccountHasNoFiles: "This account has no files",
  noActivitiesThisWeek: "No activities this week",
  paymentUnsuccessful: "Payment unsuccessful",
  reasonError: "Reason: {error}",
  youCanTryAgainWithADifferent: "You can try again with a different payment method or ",
  chatWithUs: " chat with us",
  toFindTheRightSolution: "  to find the right solution for you.",
  gotItThanks: "Got it, Thanks!",
  selectDateOfBirth: "Select date of birth",
  accountBalance: "Account balance",
  nonLinear: "Non linear",
  needMoreOptions: "Need more options? ",
  weCanHelp: "We can help.",
  chooseAnOption: "Choose an option",
  suitsYouBest: " that works best for you",
  preparingYourOptions: "Preparing your options...",
  hardshipIntro: "Hardship Intro",
  hardshipPlans: "Hardship Plans",
  getStartedWithFlexibleRates: "Get started with flexible rates and options to get back on track.",
  startWithSmallerPayments: "Start with smaller payments",
  for3To6MonthsThenMoreLater: ", for 3-6 months, then more later",
  noInterestOrFees: "No interest or fees",
  onAnyAndAllOfferedPlans: ",  on any and all offered plans.",
  avoidANegativeListing: "Avoid a negative listing",
  byUsOnYourCreditReport: " by us on your credit report.",
  whatIfINeedToAdjustItLater: "What if I need to adjust it later?",
  wereJustAPhoneCallOrChatAway:
    "We're just a phone call or chat away. Just let us know how we can help (please provide a 3-business-day notice).",
  youCanAlwaysResolveThisDebt: "You can always resolve this debt with a one time payment at any time.",

  // payment link generation
  generatePaymentLink: "Generate payment link",
  copyPaymentLink: "Copy payment link",
  linkWillBeValidFor: "The link will be valid for the next 72 hours.",
  pleaseNote: "Please note",
  generatingThisLinkWillCreate:
    "Generating this link will create an incomplete payment plan. For the payment plan to become active, the consumer must add payment details using the link.",

  //digest
  callReason: "Call’s Reason",
  verificationDetails: "Verification Details",
  talkOffPoints: "Talk-off Points",
  otherAccounts: "Other Accounts ({numberOfAccounts})",
  view: " | view",
  lastPhoneContact: "Last phone contact",
  latestComment: "Latest Comment",
  properties: "Properties",
  dateOfBirth: "Date of birth",
  enterYourDateOfBirth: "Enter your date of birth",
  address: "Address",
  when: "When",
  response: "Response",
  outcome: "Outcome",
  summary: "Summary",
  amountOwed: "Amount owed",
  currentCreditor: "Current creditor",
  originalCreditor: "Original creditor",
  placementDate: "Placement Date",
  preferredLanguage: "Preferred Language",
  assignedRe: "Assigned RE",
  assignedReModelTitle: "Assign Account to REx",
  assignedReModelDescription: "The account will be assign to the selected REx for 14 days",
  accountFlags: "Account Flags",
  userFlags: "User Flags",
  paymentPlan: "Payment Plan",
  discount: "Discount",
  totalBalanceDue: "Total balance due",
  autopay: "AutoPay",
  lastPayment: "Last Payment",
  failedReason: "Failed Reason",
  temporaryReliefPlanEnding: "Temporary relief plan ending at ",
  nonLinearPaymentsOfVaryingAmounts: "non-linear payments of varying amounts",
  labelSSN: "last 4 digits of SSN",

  // Account
  accounts: "Accounts",
  yourAccounts: "Your Accounts",
  viewMore: "View more",
  viewLess: "View less",
  from: "From",
  managePlan: "Manage plan",
  resolveNowForAsLittleAs: "Resolve now for as little as ",
  resolveNowFor: "Resolve now for ",
  paidTemporaryPlan: " paid (Temporary plan)",
  totalBalancePaid: "Total balance paid: ",
  atRiskOfaCreditReport: "At risk of a credit report",
  pendingPlan: "Pending plan",
  disputeFiled: "Dispute filed",
  fileNewDispute: "File a new dispute",
  closeDispute: "Close Dispute",
  MarkDisputeAsResponded: "Mark Dispute As Responded",
  selectCannedResponseTemplate: "Select canned response template",
  represented: "Represented",
  activePlan: "Active plan",
  unpaid: "Unpaid",
  accountResolved: "Account resolved",
  temporaryPlan: "Temporary plan",
  paymentIssue: "Payment issue",
  paidOutFrom: " paid out of ",
  somethingLooksOff: "Something looks off? ",
  nextDueDate: "Next due date: ",
  lateInstallmentAmount: "Late installment amount: ",
  wasDueOn: "Was due on ",
  negativeEntryOnCreditReport: "A negative entry was made on your credit report",
  onceYourPaymentPlanCompleted:
    "Once your payment plan is completed and account is resolved, we will notify the credit bureaus to remove the listing of this debt that Bounce AI, Inc has reported.",
  creditReportListingIn: "Credit report listing in {days} days",
  resolveDebtToPreventCollectionsListing: "Resolve this debt to prevent a collections listing on your credit report.",
  importantNotice: "Important notice",
  yourDebtIsInDispute: "Your debt is in dispute",
  yourDisputeSuccessfullyFiled:
    "Your dispute was successfully filed and is under investigation. We'll update you soon. If you have any questions or concerns, contact us or call at (888) 482-5060.",

  // Dispute v2
  fileDispute: "File a Dispute",
  labelEmail: "Email",
  confirmEmail: "Please confirm the email address you want to use to communicate about this dispute.",
  enterEmail: "Please enter the email address you want to use to communicate about this dispute.",
  fewThingsToNote: "Few things to note",
  disputeInfo1:
    "If you believe there is a mistake with this debt or the details are incorrect, you may file a dispute and we will investigate the account.",
  disputeInfo2: "If the account details are verified to be correct, debt collection activities may resume.",
  disputeInfo3: "Please provide as much information and documentation as possible to support your claim.",
  proceedWithDispute: "Proceed with dispute",
  wantToStop: "I want to stop and ",
  goBack: "go back",
  yourEmailIsInvalid: "Your Email is invalid",
  reasonForDispute: "Reason for dispute?",
  debtWasPreviouslyPaid: "Debt was previously paid",
  thisDebtHasAlreadyBeenPaid: "This debt has already been paid and I have receipts or bank statements to prove it.",
  incorrectDebtDetails: "Incorrect debt details",
  thereIsAnErrorWithTheAmount:
    "There is an error with the amount or other information about this debt and I have documents to prove it.",
  verificationOfDebt: "Verification of debt",
  iWouldLikeToRequestVerification: "I would like to request verification of this debt",
  identifyTheftOrFraud: "Identity theft or fraud",
  thisAccountWasOpenedFraudulently:
    "This account was opened and/or used fraudulently without my permission and I have filed a FTC fraud report and police report.",
  reviewYourAccountDetails: "Review your account details",
  reviewYourAccountDocuments: "Review your account documents",
  debtOwner: "Debt owner",
  last4digitsSSN: "last 4 digits of SSN",
  principal: "Principal",
  interest: "Interest",
  fees: "Fees",
  asOfYouOwed: "As of {itemization_date}, you owed: {total_balance_on_itemization_date}. Since then you were charged: ",
  interestYouWereCharged: "You were charged this amount in interest: ",
  feesYouWereCharged: "You were charged this amount in fees: ",
  youPaid: "You paid or were credited this amount: ",
  exit: "Exit",
  yourClaimWasSent: "Your claim was sent successfully",
  weWillReview: "We will review all of the details and get back to you within the next few days",
  yourFraudClaimHasBeen: "Your fraud claim has been submitted",
  weLookForwardToHearingFromYou: "We look forward to hearing from you",
  pleaseExplainYourDispute: "Please explain your dispute and attach supporting documentation",
  uploadFiles: "Upload files",
  upload: "Upload",
  dragAndDrop: "Drop files here or click to upload",
  dragAndDropSingleFile: "Drop your file or click to upload",
  afterReviewingTheAccountDetails:
    "After reviewing the account details and documentation that we have on file, do you still want to proceed?",
  multiAccount: "Multi account",

  //Dispute step 4 options
  continueWithTheDispute: "Continue with the dispute",
  solidCaseToShare: "I have a solid case to share",
  chatWithUsOnline: "Chat with us online or give us a call",
  viewMyPaymentOptions: "View my payment options",
  findOtherAvailableResolutions: "Find other available resolutions",
  uploadProofOfPayment: "Upload proof of payment",
  theRelevantFilesReady: "I have the relevant files ready",
  fileFraudClaim: "File a fraud claim",
  provideFraudDocumentation: "Provide fraud documentation",
  or: "Or",
  fraudClaimProcess: "Fraud claim process",
  inOrderToProcessYourFraudClaim:
    "In order to process your fraud claim, you need to provide in the next 14 days a copy of the following documents:",
  FTCIdentityTheftReport: "FTC Identity Theft Report",
  goOnlineAt: "Go online at: ",
  govLink: "http://www.identitytheft.gov",
  toCompleteTheFTC: ", to complete the FTC Identity Theft process and return a copy of the submitted report.",
  policeReport: "Police Report",
  fileReportAtYourLocalPolice: "File a report at your local police station and return a copy.",
  iRegret: "I regret, ",
  exitTheProcessNow: "exit the process now",
  iAccept: "I accept",
  viewFile: "View",

  // tooltips
  havingIssuesWithOutResolution: "Having issues with our resolution proposals? ",
  dropsUsALine: "Drop us a line",
  needSomeHelp: "Need some help?",
  cantAffordAnyOfThesePlans: "Can't afford any of these plans?",
  reachOutToOneOfOurResolutionExperts: "Reach out to one of our resolution experts. ",
  chatNow: "Chat Now",
  chatWithUsNow: "Chat with us now",
  cardVerificationRequireTitle: "Card Verification Required",
  creditCardVerificationRequireMessage:
    "Please be aware that you may see a $0.01 transaction hit your account to validate that your credit card information is correct. If successful, the $0.01 transaction will be voided and your payment plan will proceed as agreed.",
  debitCardVerificationRequireMessage:
    "Please be aware that you may see a $0.01 transaction hit your account to validate that your debit card information is correct. If successful, the $0.01 transaction will be voided and your payment plan will proceed as agreed.",
  largePaymentSplitTitle: "Payment Split Is Required",
  largePaymentSplitMessage:
    "Due to system limitations, payments of $10,000 or more may be split into multiple consecutive payments which sum to the agreed amount.",
  moreThanOneFile: "Can import one file only",

  //menu
  home: "Home",
  generalOptions: "General Options",
  premiumOffers: "Premium offers",
  paymentPreferences: "Payment preferences",
  manageContactDetails: "Manage contact details",
  enableAutoPay: "Enable AutoPay",
  switchToAutoPay: "Switch to AutoPay",
  getHelpFromSupport: "Get help from support",
  logout: "Log out",

  // Dialogs
  enableAutoPayPartOne: "Have all your payments scheduled and paid automatically instead of manually paying each one.",
  enableAutoPayPartTwo:
    "Enable AutoPay for hassle-free management. It's a simple “set it and forget it” solution you can enjoy at no extra cost that helps you stay on track and avoid late payments that could impact your plan.",
  stayWithManualPayment: "I prefer to stay with ",
  sms: "sms",
  bySMS: "By SMS",
  byEmail: "By Email",
  byPhoneCall: "By Phone Call",
  regret: "I regret ",
  leaveMyPlanAsIs: "leave my plan as is",
  higherThanTotalBalance: "Higher than the total balance",

  // Homepage
  viewDetailsAndDocuments: "View details & documents",
  openDate: "Open date",
  yourTotalBalanceDue: "Your total balance due",
  balanceBreakdown: "Balance breakdown",
  accountDocumentations: "Account documents",
  creditReportListingDays: "Credit report listing in {days} days",
  preventThisDebt:
    "Prevent this debt from being listed on your credit report with a repayment plan that works for you.",
  thisDebtIsReflectedOnYourCreditReport:
    "This debt is currently reflected on your credit report. View your options and take action now to resolve it and have your credit report updated.",

  // Validation notice
  generalInformation:
    "Bounce AI, Inc. is a debt collector. We are trying to collect a debt that you owe to {originalCreditor}. We will use any information you give us to help collect the debt. You had a {product} from {originalCreditor} with account number {externalAccountId}.",
  asYouOwed: "As of {itemizationDate}, you owed: {totalBalanceOnItemizationDate}. Between {itemizationDate} and today:",
  interestCharged: "You were charged this amount in interest: {totalInterestAccruedSinceItemizationDate}",
  feesCharged: "You were charged this amount in fees: {totalFeesAccruedSinceItemizationDate}",
  youPaidOrCredited: "You paid or were credited this amount toward the debt: -{totalPaidOnAccountSinceItemizationDate}",
  totalAmountOfDebt: "Total amount of the debt now: {totalBalance}",
  howCanYouDispute: "How can you dispute the debt?",
  disputeFirst: "Email, call or visit our convenient app by {lastDateToRespond}, to dispute all or part of the debt.",
  disputeSecond: "If you do not, we will assume that our information is correct.",
  ifYouNotifyBy: "If you notify us by {lastDateToRespond},",
  disputeThird:
    "we must stop collection activity on any account you dispute until we send you information that shows you owe the debt. You may use the form below or contact us without the form. You may use the link below to notify us and include supporting documents. We also accept disputes electronically by email at dispute@finbounce.com or by visiting our app at app.finbounce.com.",
  whatCanYouDo: "What else can you do?",
  whatCanYouDoFirst:
    "Contact us to ask for the name and address of the original creditor, if different from the current creditor.",
  whatCanYouDoSecond:
    "If you contact us by {lastDateToRespond}, we must stop collection until we send you that information. You may use the link below or we also accept such requests electronically by email at compliance@finbounce.com or by visiting our app at app.finbounce.com.",
  originalCreditorInfo: "Original Creditor Info",
  learnMoreOnYourRightsFirst: "Go to www.cfpb.gov/debt-collection to learn more about your rights under federal law.",
  learnMoreOnYourRightsSecond: "have the right to stop or limit how we contact you.",
  learnMoreOnYourRightsThird: "Contact us about your payment options.",
  bounceAiReferenceNumber: "Your Bounce AI reference number is: {accountPublicId}",
  spanishValidation: "Póngase en contacto con nosotros para solicitar una copia de este formulario en español.",
  spanishLink: "Quiero este formulario en español",
  validateAccount: "validate",
  validationRequired: "Validation Required:",
  addresses: "addresses",
  emails: "emails",
  readTheFollowingStatement: "Read the following statement:",
  rePhoneValidation:
    "I am calling today about your {product} that was opened with {originalCreditor}." +
    "\n" +
    "There is an outstanding balance of {totalBalance}. \n" +
    "\n" +
    "I must inform you that you may email, call or visit our convenient app by {lastDateToRespond}, to dispute all or part of the debt. If you do not, we will assume that our information is correct. You may also contact us by this date to ask for the name and address of the original creditor, if different from the current creditor. \n" +
    "\n" +
    "If you notify us by {lastDateToRespond} that you dispute or require further information, we will stop collection activity on your account until we send you the requested information. You may inform us of a dispute or information request over the phone by calling us at 888-482-5060, or we also accept such notices electronically by email at dispute@finbounce.com or by visiting our app at app.finbounce.com.",

  // Holiday campaign
  delayYourUpcomingEntry: "Delay your upcoming entry",
  asPartOfOurPeopleFirstCommitment:
    "As part of our people-first commitment, we want to ensure a smoother holiday season.",
  postponeTheReporting:
    "We’ll postpone the reporting of your debt to the credit bureaus and the entry on your credit report.",
  claimNow: "Claim now",
  happyHolidays: "Happy Holidays",
  yourCreditReportEntry: "Your credit report entry is successfully postponed",
  failedToPostpone: "Failed to postpone the reporting of your debt. Please contact us.",
  alreadyPostponed: "Your credit report entry has already been postponed.",
  paymentPlanIsActiveOnPostpone: "Payment plan is active. No need to postpone credit report entry",

  // Switch accounts
  accountsAll: "Accounts ({numberOfAccounts})",
  thisCustomerHasAccounts: "This customer has {numberOfAccounts} accounts on our system,",
  toViewAllAccounts: " to view all accounts",

  // Backoffice
  adminPanel: "Admin Panel",
  pricing: "Pricing",
  history: "History",
  dsc: "DSC",
  dscPanel: "DSC Panel",
  rexManagement: "REx Management",
  bankruptcies: "Bankruptcies",
  representationCompanies: "Representation companies",
  bulkOperations: "Bulk operations",
  anonymousEmail: "Anonymous emails",
  areYouSureYouWantToMarkThisEmailAsRead: "Are you sure you want to mark this email as read?",
  markAsRead: "Mark as read",
  assignedEmailToAccountSuccessfully: "Assigned email to account successfully",
  failedToAssignEmailToAccount: "Failed to assign email to account",
  markedEmailAsReadSuccessfully: "Marked email as read successfully",
  failedToMarkEmailAsRead: "Failed to mark email as read",
  backToSearchPanel: "Back to search panel",
  disputeClosedSuccessfully: "Dispute closed successfully",
  disputeMarkedRespondedSuccessfully: "Dispute marked as responded successfully",
  add: "Add",
  companyName: "Company name",
  addedToRepresentationCompaniesSuccessfully: `Added "{name}" to representation companies successfully`,
  failedToAddToRepresentationCompanies: `Failed to add "{name}" to representation companies`,
  yourRequestBeingProceed: "Your request is being proceed",
  theFileIsntReadyYet: "The file isn't ready yet",
  theFileNotFound: "The file was not found",
  messageWillBeSent: "A slack message will be sent to #app-notifications upon completion.",
  fileIdToDownload: "The file id to download is:",
  jobIdToTrack: "The job id to track is:",
  attachFiles: "Attach files",
  phones: "Phones",
  responseIsScheduledSuccessfully: "Response is scheduled or sent successfully",

  //Guest link payment
  logIn: "Log in",
  expiredLinkError: "Sorry, this link has expired",
  expiredLinkErrorExplanation:
    "To continue, you can either log into your account and click" +
    ' on "Switch to Autopay" under the menu, or <link>chat with an expert</link> to get assistance.',
  successPageStatusExplanation: "To view the status of your plan you will need to log in to your account",
  generateDocument: "Generate document",
  attachmentsWarning: "Please note that the original email has attachments - please attach them manually if needed",
  lastWash: "Last wash: {date}",
  isThisHardshipPlan: "Is this a “Hardship plan”?",
};
