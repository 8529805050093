import React, {FunctionComponent, PropsWithChildren} from "react";
import {Colors, Container, LabeledText, Text} from "../../../../bounce-ui/consumer";
import {PaymentType} from "../../../../declerations/enums";

interface OneCentAuthorizationLabelProps extends PropsWithChildren<any> {
  type: PaymentType;
}

const OneCentAuthorizationLabel: FunctionComponent<OneCentAuthorizationLabelProps> = ({
  children,
  type,
}: OneCentAuthorizationLabelProps) => {
  return (
    <LabeledText sx={{backgroundColor: Colors.grey._100}}>
      <Container sx={{alignItems: "start", padding: "16px 20px"}}>
        <Text translateId={"cardVerificationRequireTitle"} fontWeight={"bold"} />
        {type === "CreditCard" ? (
          <Text translateId={"creditCardVerificationRequireMessage"} />
        ) : (
          <Text translateId={"debitCardVerificationRequireMessage"} />
        )}
      </Container>
    </LabeledText>
  );
};
export default OneCentAuthorizationLabel;
