import {Outlet} from "react-router-dom";
import {experimentalStyled as styled} from "@mui/material/styles";
import AccountHeader from "./AccountHeader";
import {
  FOOTER_DESKTOP_HEIGHT,
  FOOTER_MOBILE_HEIGHT,
  NAVBAR_DESKTOP_HEIGHT,
  NAVBAR_MOBILE_HEIGHT,
} from "../../constants/ui";
import AccountFooter from "./AccountFooter";
import {FunctionComponent} from "react";
import {useUi, useUser} from "../../contexts";
import {Colors, LottieBounceAnimation} from "../../bounce-ui/consumer";

const RootStyle = styled("div")({
  display: "flex",
  overflowY: "auto",
  flexDirection: "column",
});

const MainStyle = styled("div")(({theme}) => ({
  flexGrow: 1,
  paddingTop: NAVBAR_MOBILE_HEIGHT,
  paddingBottom: FOOTER_MOBILE_HEIGHT,
  [theme.breakpoints.up("lg")]: {
    paddingTop: NAVBAR_DESKTOP_HEIGHT,
    paddingBottom: FOOTER_DESKTOP_HEIGHT,
  },
}));

type AccountLayoutProps = {};

const AccountLayout: FunctionComponent<AccountLayoutProps> = () => {
  const {isAgent} = useUser();
  const {showSplashScreen, setShowSplashScreen} = useUi();

  return (
    <RootStyle>
      <div style={{minHeight: "100vh", backgroundColor: Colors.tertiary._100}}>
        {!isAgent && <AccountHeader />}
        {showSplashScreen ? (
          <LottieBounceAnimation handleComplete={() => setShowSplashScreen(false)} />
        ) : (
          <MainStyle>
            <Outlet />
          </MainStyle>
        )}
      </div>
      {!isAgent && <AccountFooter />}
    </RootStyle>
  );
};

export default AccountLayout;
