export const defaultLocale = "en";

export const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:3001";

export const sentryDsn =
  process.env.REACT_APP_SENTRY_DSN ||
  "https://59832605bd204552814252abfccda7aa@o4504748384845824.ingest.sentry.io/4505203505954816";
export const amplitudeToken = process.env.REACT_APP_AMPLITUDE_TOKEN || "58c33d8186983e17b16bd690e8e24dc7";
export const weglotToken = "wg_3a6340c568240f5d08bb44b329e3d13c5";
export const env = process.env.REACT_APP_ENV || "local";

export const BounceAddressDetails = {
  street_line_1: "2850 Clover Street",
  street_line_2: "Suite 3",
  city: "Pittsford",
  state: "New York",
  zipcode: "14534",
  phone_numbers: ["+18884825060"],
  fax_numbers: ["818.503.1961"],
};
