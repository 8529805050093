import {UrlInfo} from "../declerations/internal";
import {findLast} from "lodash";
import {matchPath} from "react-router-dom";

function asUrlInfos<T extends {[key: string]: UrlInfo}>(arg: T): T {
  return arg;
}

export const urls = asUrlInfos({
  // Error Root
  error: {
    url: "/error",
    path: "error",
    titleKey: "error",
  },
  // Backoffice Root
  backoffice: {
    url: "/backoffice",
    path: "backoffice",
    titleKey: "backoffice",
  },
  searchPanel: {
    url: "/backoffice/search-panel",
    path: "search-panel",
    titleKey: "searchPanel",
  },
  adminPanel: {
    url: "/backoffice/admin-panel",
    path: "admin-panel",
    titleKey: "adminPanel",
  },
  rexManagement: {
    url: "/backoffice/rex-management",
    path: "rex-management",
    titleKey: "rexManagement",
  },
  bankruptcies: {
    url: "/backoffice/bankruptcies",
    path: "bankruptcies",
    titleKey: "bankruptcies",
  },
  history: {
    url: "/backoffice/history",
    path: "history",
    titleKey: "history",
  },
  pricing: {
    url: "/backoffice/pricing",
    path: "pricing",
    titleKey: "pricing",
  },
  dscPanel: {
    url: "/backoffice/dsc-panel",
    path: "dsc-panel",
    titleKey: "dscPanel",
  },
  dscBulkOperations: {
    url: "/backoffice/dsc-panel/bulk-operations",
    path: "dsc-panel/bulk-operations",
    titleKey: "bulkOperations",
  },
  dscAnonymousEmails: {
    url: "/backoffice/dsc-panel/anonymous-emails",
    path: "dsc-panel/anonymous-emails",
    titleKey: "anonymousEmail",
  },
  representationCompanies: {
    url: "/backoffice/representation-companies",
    path: "representation-companies",
    titleKey: "representationCompanies",
  },
  informationPanel: {
    url: "/backoffice/information-panel",
    path: "information-panel",
    titleKey: "informationPanel",
  },
  backofficeLogin: {
    url: "/backoffice/login",
    path: "backoffice/login",
    titleKey: "backofficeLogin",
  },
  // Auth Root
  auth: {
    url: "/auth",
    path: "auth",
    titleKey: "login",
  },
  login: {
    url: "/auth/login",
    path: "login",
    titleKey: "login",
  },
  deprecatedLogin: {
    url: "/auth/login/:userId",
    path: "login/:userId",
    titleKey: "login",
  },
  verifyAccount: {
    url: "/auth/verify-account/:userId",
    path: "verify-account/:userId",
    titleKey: "verifyAccount",
  },
  verifyAnonymousAccount: {
    url: "/auth/verify-account",
    path: "verify-account",
    titleKey: "verifyAccount",
  },
  verifyCode: {
    url: "/auth/verify-code/:userId",
    path: "verify-code/:userId",
    titleKey: "verifyCode",
  },
  verifyCodeFromUrl: {
    url: "/auth/verify-code/:userId/:contactType/:contactDetail/:code",
    path: "verify-code/:userId/:contactType/:contactDetail/:code",
    titleKey: "verifyCode",
  },
  verifyCodeFromUrlWithCampaign: {
    url: "/auth/verify-code/:userId/:contactType/:contactDetail/:campaign/:code",
    path: "verify-code/:userId/:contactType/:contactDetail/:campaign/:code",
    titleKey: "verifyCode",
  },
  // Account Root
  account: {
    url: "/account",
    path: "account",
    titleKey: "accountInformation",
  },
  accountMain: {
    url: "/account/:accountId",
    path: ":accountId",
    titleKey: "account",
  },
  accounts: {
    url: "/accounts",
    path: "accounts",
    titleKey: "accounts",
  },
  accountInformation: {
    url: "/account/:accountId/account-information",
    path: ":accountId/account-information",
    titleKey: "accountInformation",
  },
  accountDashboard: {
    url: "/account/:accountId/account-dashboard",
    path: ":accountId/account-dashboard",
    titleKey: "dashboard",
  },
  dispute: {
    url: "/account/:accountId/dispute",
    path: ":accountId/dispute",
    titleKey: "dispute",
  },
  disputeSuccess: {
    url: "/account/:accountId/dispute-success",
    path: ":accountId/dispute-success",
    titleKey: "disputeSuccess",
  },
  presetPlanCheckout: {
    url: "/account/:accountId/checkout/plan",
    path: ":accountId/checkout/plan",
    titleKey: "checkout",
  },
  paymentSuccess: {
    url: "/account/:accountId/payment-success",
    path: ":accountId/payment-success",
    titleKey: "paymentSuccess",
  },
  calendarReminders: {
    url: "/account/:accountId/calendar-reminders",
    path: ":accountId/calendar-reminders",
    titleKey: "calendarReminders",
  },
  // Payment plan
  planOptions: {
    url: "/account/:accountId/plan-options",
    path: ":accountId/plan-options",
    titleKey: "PlanOptions",
  },
  choosePlan: {
    url: "/account/:accountId/choose-plan",
    path: ":accountId/choose-plan",
    titleKey: "choosePlan",
  },
  summaryPage: {
    url: "/account/:accountId/summary",
    path: ":accountId/summary",
    titleKey: "summary",
  },
  singlePayment: {
    url: "/account/:accountId/checkout/single",
    path: ":accountId/checkout/single",
    titleKey: "checkout",
  },
  partialPayment: {
    url: "/account/:accountId/partial-payment",
    path: ":accountId/partial-payment",
    titleKey: "checkout",
  },
  guestPaymentLinkRoot: {
    url: "/guest-checkout",
    path: "guest-checkout",
    titleKey: "checkout",
  },
  guestPaymentLinkCheckout: {
    url: "/guest-checkout/:paymentPlanId",
    path: ":paymentPlanId",
    titleKey: "checkout",
  },
  guestPaymentLinkSuccess: {
    url: "/guest-checkout/payment-success",
    path: "payment-success",
    titleKey: "paymentSuccess",
  },
  hardshipIntro: {
    url: "/account/:accountId/hardship-intro",
    path: ":accountId/hardship-intro",
    titleKey: "hardshipIntro",
  },
  hardshipPlans: {
    url: "/account/:accountId/hardship-plans",
    path: ":accountId/hardship-plans",
    titleKey: "hardshipPlans",
  },
});

export const getUrlInfo = (pathname: string): UrlInfo | undefined => {
  return findLast(urls, (url) => !!matchPath({path: url.url}, pathname));
};
