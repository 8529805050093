import React, {ReactNode} from "react";
import {Theme, Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {SxProps} from "@mui/system";
import {LabelColors} from "../../pages/backoffice/information-panel/labels-utils";
import {Colors} from "../colors";

const getBackgroundColor = (backgroundColor: string) => {
  if (backgroundColor === "green") {
    return Colors.green._900;
  }
  if (backgroundColor === "yellow") {
    return Colors.orange._800;
  }
  if (backgroundColor === "red") {
    return Colors.red._900;
  }
  if (backgroundColor === "primary") {
    return Colors.primary._800;
  }
  return backgroundColor;
};

const convertPxToRem = (pxSize: number) => {
  const rem = pxSize / 16;
  return `${rem}rem`;
};

export type ITextProps = {
  translateId?: string;
  variant?: "h1" | "h2" | "body1" | "body2";
  /*
    h1: Helvetica Now Display
    h2: Helvetica Now Display Medium
    body1: Helvetica Now Text
    body2: Helvetica Now Text Medium
   */
  fontWeight?: "light" | "regular" | "medium" | "bold" | number; // 300 | 400 | 600 | 700
  size?: 11 | 12 | 13 | 14 | 16 | 18 | 20 | 22 | 24 | 26 | 28 | 32 | 40 | 50; // This is in px. in rem: 0.6875 | 0.75 | 0.8125 | 0.875 | 1 | 1.125 | 1.25 | 1.375 | 1.5 | 1.625 | 2 | 2.5 |3.125;
  color?: string;
  values?: any;
  sx?: SxProps<Theme>;
  children?: ReactNode;
};

export interface ILabeledTextProps extends ITextProps {
  color?: string;
  backgroundColor?: LabelColors;
}

const getVariant = (size: number, fontWeight: string | number): ITextProps["variant"] | undefined => {
  if (size < 18) {
    if (["light", "regular", 500].includes(fontWeight)) {
      return "body1";
    } else if (["medium", "bold"].includes(String(fontWeight))) {
      return "body2";
    }
  } else {
    if (["light", "regular", 500].includes(fontWeight)) {
      return "h1";
    } else if (["medium", "bold"].includes(String(fontWeight))) {
      return "h2";
    }
  }
  return "body1";
};

/***  Text usage: ***/
/*
To render an explicit string use translateId prop and add your text to en_US.ts file:
  <Text translateId={"string"}/>
To render a string parameter use pass the text as children:
  <Text>{string}</Text>
*/

export const Text = ({
  translateId,
  fontWeight = "regular",
  size = 16,
  variant,
  color,
  values,
  sx,
  children,
}: ITextProps) => {
  const fontFamilyVariant = variant || getVariant(size, fontWeight);
  return (
    <Typography
      className={"Text"}
      component={"div"}
      variant={fontFamilyVariant}
      sx={{
        color: (theme) => color ?? theme.palette.text.primary,
        fontSize: convertPxToRem(size),
        alignItems: "center",
        ...sx,
      }}
    >
      {translateId && <FormattedMessage id={translateId} values={values} />}
      {children}
    </Typography>
  );
};

export const LabeledText = ({
  color,
  backgroundColor = "primary",
  translateId,
  variant,
  children,
  sx,
  ...props
}: ILabeledTextProps) => {
  return (
    <Text
      size={14}
      translateId={translateId}
      variant={variant}
      sx={{
        backgroundColor: getBackgroundColor(backgroundColor),
        color: color || Colors.bw.white,
        padding: "1px 10px",
        borderRadius: "5px",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Text>
  );
};
