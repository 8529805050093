import {axiosInstance} from "../../ServerApiManager";
import axios from "axios";

type Variables = {
  accountId?: string;
  fileId: string;
  isAgent?: boolean;
  isMobile?: boolean;
  viewMode?: boolean;
  fileName?: string;
  htmlView?: boolean;
};

type DownloadLink = {
  url: string;
  file_name: string;
};

export const isNotChromeOniPhone = () => {
  const userAgent = navigator.userAgent;
  const isiPhone = /iPhone/i.test(userAgent);
  const isChrome = /CriOS/i.test(userAgent);

  return isiPhone && !isChrome;
};

export const getUserFile = async (variables: Variables): Promise<DownloadLink> => {
  const url = variables?.isAgent
    ? `/backoffice/files/download-link/${variables.fileId}`
    : `/frontend/files/download-link/${variables.accountId}/${variables.fileId}`;
  const result = await axiosInstance.get<DownloadLink>(url, {params: {view_mode: variables.viewMode}});
  return result.data;
};

export const downloadUserFiles = async (variables: Variables): Promise<void | string> => {
  const viewMode = getViewMode(variables.fileName, variables.viewMode);
  variables.viewMode = viewMode;
  const {url, file_name} = await getUserFile(variables);
  if (variables.htmlView) {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "text/html",
      },
    });
    return response.data;
  }
  const link = document.createElement("a");
  link.href = url;
  link.download = file_name;
  if (viewMode) {
    link.target = "_blank";
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const getViewMode = (fileName?: string, viewMode?: boolean): boolean => {
  if (!viewMode || !fileName) {
    return false;
  }
  const isSafari = isNotChromeOniPhone();

  return (
    !isSafari &&
    ["pdf", "html", "png", "jpeg", "jpg", "svg", "svg+xml", "tif", "tiff", "mp3"].some((fileType) =>
      fileName?.endsWith(fileType)
    )
  );
};
