import React, {FunctionComponent, useCallback, useMemo} from "react";
import {Box} from "@mui/material";
import {DropEvent, DropzoneProps, FileRejection} from "react-dropzone";
import Dropzone from "react-dropzone";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {isEmpty} from "lodash";
import {getFilesRejectedMessageKey} from "../../../helpers/FileUtils";
import {useSnackbar} from "notistack";
import {Colors, Container, IconUpload, Text} from "../index";
import {FilesPreview} from "./FilePreview";
import useIsMobile from "../../../helpers/useIsMobile";

export enum MaxFileSizeOption {
  NON_GZ_SIZE_LIMIT = 10,
  GZ_SIZE_LIMIT = 50,
}

interface FileDropzoneProps extends DropzoneProps {
  sx?: SxProps<Theme>;
  addFilesText?: string;
  files: File[];
  onChange: (event: any) => void;
  maxFiles?: number;
  textPosition?: "start" | "center";
  maxSize?: MaxFileSizeOption;
  height?: string;
  isDialog?: boolean;
}

const notExistsOnArray = ({newFile, files}: {newFile: File; files: File[]}) => {
  return !files.some((file) => file.name === newFile.name);
};

const mapMaxSizeOptionToBytes = {
  [MaxFileSizeOption.NON_GZ_SIZE_LIMIT]: 10485760,
  [MaxFileSizeOption.GZ_SIZE_LIMIT]: 52428800,
};

export const FileDropzone: FunctionComponent<FileDropzoneProps> = ({
  sx,
  addFilesText,
  files,
  onChange,
  maxFiles,
  textPosition = "start",
  maxSize,
  height,
  isDialog = true,
  ...dropzoneProps
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const isMobile = useIsMobile();
  const dropFilesText = addFilesText ?? (isMobile ? "uploadFiles" : "dragAndDrop");
  const onDropInternal = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
      if (!isEmpty(acceptedFiles)) {
        const appendedFiles: File[] = [];
        acceptedFiles.forEach((newFile) => {
          if (notExistsOnArray({newFile, files})) {
            appendedFiles.push(newFile);
          }
        });
        onChange([...files, ...appendedFiles]);
      }

      if (!isEmpty(fileRejections)) {
        fileRejections.forEach((fileRejection) =>
          enqueueSnackbar(<Text translateId={getFilesRejectedMessageKey(fileRejection)} size={14} />, {
            variant: "error",
          })
        );
      }
    },
    [files]
  );

  const isAboveMaxFiles = !!(maxFiles && files.length > maxFiles);

  const hasFiles = useMemo<boolean>(() => !isEmpty(files), [files]);
  const maxSizeInBytes = (maxSize && mapMaxSizeOptionToBytes[maxSize]) ?? undefined;
  return (
    <Dropzone {...dropzoneProps} onDrop={onDropInternal} maxFiles={maxFiles} maxSize={maxSizeInBytes}>
      {({getRootProps, getInputProps}) => (
        <Container fullWidth sx={{alignItems: "center"}}>
          {hasFiles && <FilesPreview files={files} onChange={onChange} isDialog={isDialog} />}
          <Box
            {...getRootProps({})}
            sx={{
              cursor: "pointer",
              width: "100%",
              height: height ?? "90px",
              backgroundColor: Colors.primary._100,
              borderRadius: "8px",
              border: `1px dashed`,
              borderColor: Colors.primary._800,
              ...sx,
            }}
          >
            <input {...getInputProps()} />
            <Container fullWidth sx={{height: "100%", justifyContent: "center", gap: height ? "5px" : "10px"}}>
              <Text translateId={dropFilesText} variant={"body2"} sx={{color: Colors.primary._800}} />
              <IconUpload />
            </Container>
            {isAboveMaxFiles && <Text translateId="moreThanOneFile" />}
          </Box>
          {dropzoneProps.disabled && (
            <Box
              sx={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: (theme) => theme.palette.action.disabledBackground,
                opacity: (theme) => theme.palette.action.disabledOpacity,
              }}
            />
          )}
        </Container>
      )}
    </Dropzone>
  );
};
