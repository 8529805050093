import React, {useRef} from "react";
import Lottie from "lottie-react";
import {Container} from "./Container";

const LottieConfetti = ({
  animatedConfettiJson,
  transform = false,
}: {
  animatedConfettiJson: any;
  transform?: boolean;
}) => {
  const lottieRef = useRef(null);

  return (
    <Container
      sx={{
        height: "100%",
        position: "fixed",
        left: 0,
        right: 0,
        top: 100,
        bottom: 0,
        display: "flex",
        ...(transform && {transform: "rotate(180deg) scaleY(-1)"}),
      }}
    >
      <div style={{width: "400px"}}>
        <Lottie animationData={animatedConfettiJson} loop={true} lottieRef={lottieRef} />
      </div>
    </Container>
  );
};

export {LottieConfetti};
