import {AccountExternal, CreditReportDetails} from "../../../declerations/server/account_models";
import {extractDateFromString} from "../../../helpers/formatDate";
import {formatCurrency} from "../../../helpers/formatNumber";
import {UserBase, UserExternal} from "../../../declerations/server/user_models";
import {Address, Email, Phone} from "../../../declerations/server";
import {find, isEmpty, map} from "lodash";
import {FileRead} from "../../../declerations/server/files_models";
import {DATE_FORMAT_V2} from "./components/dialogs/common";
import dayjs from "dayjs";
import {get} from "lodash";
import InputUtils from "../../../helpers/InputUtils";
import {Theme} from "@mui/material/styles";
import {ActivePaymentPlan} from "../../../declerations/server/payment_plan_models";
import {Colors} from "../../../bounce-ui/colors";
import {getDaysUntilCreditReport} from "../../../helpers/CreditReportHelper";
import {prettifyCurrency} from "../../../helpers/currency";

export const accountFlagsTypes = [
  {value: "legal_eligible", label: "Legal Eligible"},
  {value: "active_complaint", label: "Active Complaint"},
  {value: "statute_barred", label: "Statute Barred"},
  {value: "creditor_recalled", label: "Creditor Recalled"},
  {value: "fraud_reported", label: "Fraud Reported"},
  {value: "cease_and_desist", label: "Cease And Desist"},
  {value: "freeze_communication", label: "Freeze Communication"},
];

export const userFlagsTypes = [
  {value: "hearing_disability", label: "Hearing Disability"},
  {value: "reading_disability", label: "Reading Disability"},
  {value: "active_military", label: "Active Military"},
  {value: "deceased", label: "Deceased"},
  {value: "unhandled_communication", label: "Unhandled Communication"},
];

export const unEditableAccountFlagsTypes = [{value: "represented", label: "Represented"}];

export type FlagType = {value: string; label: string};
interface IFiledDetails {
  detailName: string;
  detail?: string;
}

interface IGetEnabledFlags {
  data: AccountExternal | UserExternal;
  flagsTypes: FlagType[];
}

interface IGetUserFullNameByNames {
  firstName: string;
  middleName?: string;
  lastName: string;
}

interface IGetAccountCardLabel {
  theme: Theme;
  inDispute: boolean;
  creditReportDetails?: CreditReportDetails;
  represented?: boolean;
  paymentPlan?: ActivePaymentPlan;
  isAgent: boolean;
}

interface AccountCardLabel {
  label: string;
  color: string;
  action?: "manage" | "resolve";
  progressBarColor?: string;
}

export const getAccountDetails = (account: AccountExternal): IFiledDetails[] => {
  const activePP = account.active_payment_plan;
  const chargeOffAmount = account.total_balance_on_charge_off ?? 0;
  const extraInfo = account.extra_info ? Object.entries(account.extra_info) : [];
  const parsedExtraInfo = map(extraInfo, ([key, value]) => {
    return {detailName: String(key), detail: String(value)};
  });
  const details = [
    {detailName: "Representation", detail: account.representation_status},
    {detailName: "payment plan status", detail: activePP ? activePP.state : "Inactive PP"},
    {detailName: "current creditor", detail: account.current_creditor},
    {detailName: "original creditor", detail: account.original_creditor},
    {detailName: "product", detail: account.product},
    {detailName: "original account #", detail: account.external_account_id ?? "N/A"},
    {detailName: "bounce reference #", detail: account.public_id},
    {detailName: "open date", detail: extractDateFromString(account.origination_date)},
    {
      detailName: "last payment made",
      detail: account.last_payment_date ? extractDateFromString(account.last_payment_date) : "N/A",
    },
    {
      detailName: "last payment amount",
      detail: account.last_payment_amount ? formatCurrency(account.last_payment_amount) : "N/A",
    },
    {detailName: "legal eligibility", detail: "N/A"},
    {detailName: "credit reporting status", detail: "N/A"},
    {detailName: "outstanding balance", detail: formatCurrency(account.total_balance)},
    {
      detailName: "remaining plan balance",
      detail: activePP ? formatCurrency(account.current_payment_plan_balance_to_collect || 0) : "-",
    },
    {
      detailName: "total paid on account",
      detail: account.total_paid_on_account_since_itemization_date
        ? formatCurrency(account.total_paid_on_account_since_itemization_date)
        : "N/A",
    },
    {
      detailName: "charge-off date, amount",
      detail: extractDateFromString(account.charge_off_date) + "  ,  " + formatCurrency(chargeOffAmount),
    },
    {detailName: "first delinquency date", detail: dayjs(account.first_delinquency_date).format(DATE_FORMAT_V2)},
    ...parsedExtraInfo,
  ];
  if (account.current_principal !== undefined)
    details.push({detailName: "outstanding principal", detail: formatCurrency(account.current_principal)});
  if (account.current_interest !== undefined)
    details.push({detailName: "outstanding interest", detail: formatCurrency(account.current_interest)});
  if (account.current_fees !== undefined)
    details.push({detailName: "outstanding fees", detail: formatCurrency(account.current_fees)});
  return details;
};

export const getCreditReportDetails = (creditReportDetails: CreditReportDetails): IFiledDetails[] => {
  const details = [
    {
      detailName: "reporting status",
      detail: InputUtils.prettierSnakeCase(creditReportDetails.last_report_type),
    },
  ];
  if (creditReportDetails.dispute_status) {
    details.push({
      detailName: "reporting dispute status",
      detail: InputUtils.prettierSnakeCase(creditReportDetails.dispute_status),
    });
  }
  if (creditReportDetails.first_report_date) {
    details.push({
      detailName: "first report date",
      detail: dayjs(creditReportDetails.first_report_date).format(DATE_FORMAT_V2),
    });
  }
  if (creditReportDetails.last_report_date) {
    details.push({
      detailName: "last report date",
      detail: dayjs(creditReportDetails.last_report_date).format(DATE_FORMAT_V2),
    });
  }
  if (creditReportDetails.next_report_date) {
    details.push({
      detailName: "next report date",
      detail: dayjs(creditReportDetails.next_report_date).format(DATE_FORMAT_V2),
    });
  }

  if (creditReportDetails.report_data) {
    details.push({
      detailName: "reported balance",
      detail: prettifyCurrency(creditReportDetails.report_data.balance),
    });
    details.push({
      detailName: "reported payments",
      detail: prettifyCurrency(creditReportDetails.report_data.sum_of_successful_payment_since_last_report),
    });
    if (creditReportDetails.report_data.last_payment_date_since_last_report) {
      details.push({
        detailName: "reported last payment date",
        detail: dayjs(creditReportDetails.report_data.last_payment_date_since_last_report).format(DATE_FORMAT_V2),
      });
    }
  }
  return details;
};

export const getUserFullName = (user: UserBase) => {
  const firstName = user?.first_name;
  const middleName = user?.middle_name ? user?.middle_name : "";
  const lastName = user?.last_name;
  return firstName + " " + middleName + " " + lastName;
};

export const getUserFullNameByNames = ({firstName, middleName, lastName}: IGetUserFullNameByNames) => {
  const getMiddleName = middleName ? middleName + " " : "";
  return firstName + " " + getMiddleName + lastName;
};

export const getDefaultPhone = (userPhones?: Array<Phone>): string => {
  if (!userPhones || isEmpty(userPhones)) return "";
  const primary = find(userPhones, (phone: Phone) => phone.is_primary);
  return primary ? primary.phone : userPhones[0].phone;
};

export const getDefaultAddress = (userAddresses?: Array<Address>): string => {
  if (!userAddresses || isEmpty(userAddresses)) return "";
  const primary = find(userAddresses, (address: Address) => address.is_primary);
  if (primary) {
    return primary.address || "";
  } else {
    return userAddresses[0].address || "";
  }
};
export const getDefaultEmail = (userEmails?: Array<Email>): string => {
  if (!userEmails || isEmpty(userEmails)) return "";
  const primary = find(userEmails, (email: Email) => email.is_primary);
  return primary ? primary.email : userEmails[0].email;
};

export const getUserFilesOptions = (
  files?: Array<FileRead>,
  default_file_ids?: Array<string>
): Array<{value: string; label: string}> => {
  if (isEmpty(files)) return [];
  return map(files, (file) => ({value: file.id, label: file.filename}));
};

const flagEnabled = (data: AccountExternal | UserExternal, flagName: string): string => {
  const flag = get(data, flagName);
  if (flag?.hasOwnProperty("data")) {
    return flag.data;
  }
  return flag;
};
export const getEnabledFlags = ({data, flagsTypes}: IGetEnabledFlags) => {
  const enabledFlagLabels: FlagType[] = [];
  Object.values(flagsTypes).forEach((flagName) => {
    if (flagEnabled(data, flagName.value)) {
      const flagType = flagsTypes.find((flag: {value: string; label: string}) => flag.value === flagName.value);
      if (flagType) {
        enabledFlagLabels.push({value: flagType.value, label: flagType.label});
      }
    }
  });
  return enabledFlagLabels;
};

export const getAccountCardLabel = ({
  theme,
  inDispute,
  creditReportDetails,
  represented,
  paymentPlan,
  isAgent,
}: IGetAccountCardLabel): AccountCardLabel => {
  const daysUntilReport = getDaysUntilCreditReport(creditReportDetails);
  const inRiskOfCreditReport = !!(daysUntilReport && daysUntilReport <= 30);
  if (inDispute) {
    return {
      label: "disputeFiled",
      color: isAgent ? Colors.red._700 : Colors.bw.white,
      action: "resolve",
      progressBarColor: Colors.grey._500,
    };
  }
  if (inRiskOfCreditReport) {
    return {
      label: "atRiskOfaCreditReport",
      color: isAgent ? Colors.orange._800 : Colors.red._100,
      action: "resolve",
    };
  }
  if (represented && !paymentPlan && isAgent) {
    return {
      label: "represented",
      color: isAgent ? theme.palette.text.label : Colors.red._200,
      action: "resolve",
    };
  }
  if (!paymentPlan || (paymentPlan.is_interim_plan && paymentPlan.state === "resolved")) {
    return {
      label: "pendingPlan",
      color: isAgent ? Colors.grey._500 : Colors.bw.white,
      action: "resolve",
    };
  }
  if (paymentPlan.late_installment) {
    return {
      label: "paymentIssue",
      color: isAgent ? Colors.red._900 : Colors.red._100,
      action: "resolve",
      progressBarColor: Colors.grey._500,
    };
  }
  if (paymentPlan.state === "resolved") {
    return {
      label: "accountResolved",
      color: isAgent ? Colors.green._900 : Colors.green._200,
      progressBarColor: Colors.green._900,
    };
  }
  if (paymentPlan.is_interim_plan) {
    return {
      label: "temporaryPlan",
      color: isAgent ? Colors.orange._800 : Colors.green._200,
      action: "manage",
      progressBarColor: Colors.green._900,
    };
  }
  return {
    label: "activePlan",
    color: isAgent ? Colors.green._900 : Colors.green._100,
    action: "manage",
    progressBarColor: Colors.green._900,
  };
};

export const copyToClipboard = (textToCopy: string) => {
  const input = document.createElement("textarea");
  input.value = textToCopy;
  document.body.appendChild(input);
  input.select();
  input.focus();
  document.execCommand("copy");
  document.body.removeChild(input);
};
