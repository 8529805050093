import React, {useCallback, useContext, useMemo, useState} from "react";
import {Checkbox, FormControl, InputLabel, Link, MenuItem, Select} from "@mui/material";
import NiceModal from "@ebay/nice-modal-react";
import PaymentTabPanel from "../../../../pages/account/payment/components/PaymentTabPanel";
import {PaymentMethodTypes, PaymentType} from "../../../../declerations/enums";
import {PaymentDetails} from "../../../../declerations/server";
import {useGetLenderDetailsQuery} from "apis/requests/lender/getLenderDetails";
import {useUi} from "contexts/UiContext";
import {PaymentContext} from "../../../../pages/account/payment/utils";
import {Container, LoadingButton, Text} from "../../../../bounce-ui/consumer";
import {mapMethodToValue, PaymentMethodType} from "../../../../pages/account/account-dashboard/component-v2/utils";
import TermsAndConditionsDialog from "../../../../pages/account/payment/components/TermsAndConditionsDialog";
import {Pages} from "../../../../bi-events/enum";
import {useAmplitude, useUser} from "../../../../contexts";
import {useGetUserQuery} from "../../../../apis/requests/user/getUser";

export type IPaymentMethod = {
  accountId: string;
  lastDigitsCard?: string | undefined;
  title?: string;
  submitTitle?: string;
  currentMethodType?: PaymentMethodTypes;
  paymentHandler: (paymentType: PaymentType, paymentDetails: PaymentDetails, isChangePaymentMethod: boolean) => void;
  disable?: boolean;
  page?: Pages;
  termsAndConditionsId?: string;
  closeHandler?: () => void;
};

const PaymentMethod = ({
  accountId,
  lastDigitsCard,
  title,
  paymentHandler,
  submitTitle = "payItNow",
  disable = false,
  currentMethodType,
  page,
  closeHandler,
  termsAndConditionsId = "paymentTermsAndConditions",
}: IPaymentMethod) => {
  const {pageClickEvent} = useAmplitude();
  const [checkedTerms, setCheckedTerms] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>("current");
  const {uiSettings} = useUi();
  const {isAgent} = useUser();
  const {buttonTouched, isLoading} = useContext(PaymentContext);
  const {data: user} = useGetUserQuery({});
  const achBlocked = useMemo(() => {
    if (isAgent) {
      return false;
    }
    return user && !user.ach_allowed && currentMethodType === "ach";
  }, [user]);

  const lenderDetails = useGetLenderDetailsQuery(
    {lender_name: uiSettings?.lender_id || ""},
    {enabled: !!uiSettings?.lender_id}
  );

  const termsAndConditionsHandler = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
      if (page) {
        pageClickEvent({page, cta: "t_c"});
      }
      event.preventDefault();
      NiceModal.show(TermsAndConditionsDialog, {
        termsAndConditionsTextId: lenderDetails?.data?.disclaimers.payment_terms_and_conditions,
      });
    },
    [lenderDetails.data]
  );

  const paymentMethodOption = useMemo(() => {
    const options = mapMethodToValue(currentMethodType, lastDigitsCard);
    if (!Object.keys(options).includes("current") || achBlocked) {
      setPaymentMethod("change");
    }
    return options;
  }, [user]);

  return (
    <>
      <Container fullWidth sx={{alignItems: "start"}}>
        {!achBlocked && (
          <>
            {paymentMethodOption && lastDigitsCard && (
              <FormControl variant="outlined" sx={{width: "100%"}}>
                <InputLabel>{title || "Payment method"}</InputLabel>
                <Select
                  value={paymentMethod}
                  label={title || "Payment method"}
                  onChange={(event: any) => setPaymentMethod(event.target.value)}
                  sx={{
                    height: "50px",
                    borderRadius: "8px",
                  }}
                >
                  {Object.keys(paymentMethodOption).map((method) => (
                    <MenuItem key={method} value={method}>
                      {paymentMethodOption[method]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </>
        )}
        {(!lastDigitsCard || paymentMethod === "change") && (
          <PaymentTabPanel
            onCardSubmit={paymentHandler}
            onAchSubmit={paymentHandler}
            submitLabelId={submitTitle}
            termsAndConditionsId={termsAndConditionsId}
            termsAndConditionsTextId={lenderDetails.data?.disclaimers.payment_terms_and_conditions}
            onDashboardDialog={true}
            currAccountId={accountId}
            closePaymentDialogHandler={closeHandler}
          />
        )}
      </Container>
      {lastDigitsCard && paymentMethod === "current" && (
        <Container sx={{gap: "20px"}}>
          <Container row sx={{alignItems: "start"}}>
            <Checkbox
              checked={checkedTerms}
              sx={{padding: 0, mr: 0.5}}
              onClick={() => setCheckedTerms(!checkedTerms)}
            />
            <Text
              translateId={termsAndConditionsId}
              size={12}
              values={{
                termsAndConditions: (
                  <Link href={"#"} onClick={termsAndConditionsHandler}>
                    <Text
                      size={12}
                      translateId={"termsAndConditions"}
                      fontWeight={"bold"}
                      color={"secondary"}
                      sx={{display: "inline"}}
                    />
                  </Link>
                ),
                collectionLabel: uiSettings?.collection_label || "",
              }}
            />
          </Container>
          <LoadingButton
            id={submitTitle}
            disabled={!checkedTerms || disable || buttonTouched}
            onClick={paymentHandler}
            loading={isLoading}
          />
        </Container>
      )}
    </>
  );
};

export default PaymentMethod;
