import React, {FunctionComponent, ReactNode} from "react";
import {Dialog as MuiDialog} from "@mui/material";
import {useModal} from "@ebay/nice-modal-react";
import {Button, CloseDialogButton, Container, Text} from "bounce-ui/backoffice";
import InputUtils from "../../../helpers/InputUtils";

export type DialogType = {
  title: string;
  onClick: () => void;
  onClose?: () => void;
  buttonText?: string;
  secondaryButtonText?: string;
  onClickSecondary?: () => void;
  isError?: boolean;
  width?: string;
  alignCenter?: boolean;
  children?: ReactNode;
};

export const CustomDialog: FunctionComponent<DialogType> = ({
  title,
  buttonText = "Save",
  onClick,
  onClose,
  secondaryButtonText,
  onClickSecondary,
  alignCenter = false,
  width,
  isError,
  children,
}) => {
  const modal = useModal();
  const onClickModal = async () => {
    onClick();
    modal.resolve(true);
    modal.hide();
  };

  const onClickSecondaryModal = async () => {
    onClickSecondary?.();
    modal.resolve(false);
    modal.hide();
  };

  return (
    <MuiDialog
      open={modal.visible}
      TransitionProps={{
        onExited: () => {
          onClose?.();
          modal.remove();
        },
      }}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "16px",
          padding: "44px",
          gap: "24px",
        },
      }}
    >
      <CloseDialogButton onClose={modal.hide} />
      <Container sx={{gap: "24px", width: width || "300px", alignItems: alignCenter ? "center" : "start"}}>
        <Text variant={"h2"} size={24}>
          {InputUtils.prettierSnakeCaseSentence(title)}
        </Text>
        {children}
      </Container>
      <Container sx={{gap: "8px"}}>
        <Button buttonType={isError ? "contained-error" : "contained-primary"} onClick={onClickModal} fullWidth>
          {InputUtils.prettierSnakeCaseSentence(buttonText)}
        </Button>
        {secondaryButtonText && (
          <Button
            buttonType={isError ? "outlined-secondary" : "contained-secondary"}
            onClick={onClickSecondaryModal}
            fullWidth
          >
            {InputUtils.prettierSnakeCaseSentence(secondaryButtonText)}
          </Button>
        )}
      </Container>
    </MuiDialog>
  );
};
